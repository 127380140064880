import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';

class CER025 extends Component {

    render() {

        return (
            <React.Fragment>

                { this.props.view === "outside" &&
                    <>
                        { this.props.inoxAplikacije ?
                            <React.Fragment>
                                <polygon
                                    className="izrez-inox"
                                    points={`
                                        10,400
                                        ${this.props.doorWidth-10},600
                                        ${this.props.doorWidth-10},625
                                        10,425
                                    `}
                                />
                                <polygon
                                    className="izrez-inox"
                                    points={`
                                        10,1000
                                        ${this.props.doorWidth-10},800
                                        ${this.props.doorWidth-10},825
                                        10,1025
                                    `}
                                />
                                <polygon
                                    className="izrez-inox"
                                    points={`
                                        10,1400
                                        ${this.props.doorWidth-10},1600
                                        ${this.props.doorWidth-10},1625
                                        10,1425
                                    `}
                                />
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <line
                                    x1="10"
                                    y1="400"
                                    x2={`${this.props.doorWidth - 10}`}
                                    y2="600"
                                    style={{stroke: "rgba(0, 0, 0, 0.35)", strokeWidth: "10"}}
                                />
                                <line
                                    x1="10"
                                    y1="425"
                                    x2={`${this.props.doorWidth - 10}`}
                                    y2="625"
                                    style={{stroke: "rgba(246, 246, 246, 0.35)", strokeWidth: "5"}}
                                />
                                <line
                                    x1="10"
                                    y1="1000"
                                    x2={`${this.props.doorWidth - 10}`}
                                    y2="800"
                                    style={{stroke: "rgba(0, 0, 0, 0.35)", strokeWidth: "10"}}
                                />
                                <line
                                    x1="10"
                                    y1="1025"
                                    x2={`${this.props.doorWidth - 10}`}
                                    y2="825"
                                    style={{stroke: "rgba(246, 246, 246, 0.35)", strokeWidth: "5"}}
                                />
                                <line
                                    x1="10"
                                    y1="1400"
                                    x2={`${this.props.doorWidth - 10}`}
                                    y2="1600"
                                    style={{stroke: "rgba(0, 0, 0, 0.35)", strokeWidth: "10"}}
                                />
                                <line
                                    x1="10"
                                    y1="1425"
                                    x2={`${this.props.doorWidth - 10}`}
                                    y2="1625"
                                    style={{stroke: "rgba(246, 246, 246, 0.35)", strokeWidth: "5"}}
                                />

                            </React.Fragment>
                        }

                        {this.props.handleDirection === "right" ?
                            <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="middle" x={this.props.doorWidth/2} y={250} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                            :
                            <text textAnchor="middle" x={this.props.doorWidth/2} y={250} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                        }

                    </>
                }
            </React.Fragment>
        );
    }
}

export default CER025;