import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';

class CER026 extends Component {

    render() {

        return (
            <React.Fragment>

                { this.props.view === "outside" &&
                    <>
                        { this.props.inoxAplikacije ?
                            <React.Fragment>
                                <polygon
                                    className="izrez-inox"
                                    points={`
                                        10,450
                                        ${this.props.doorWidth-10},250
                                        ${this.props.doorWidth-10},275
                                        10,475
                                    `}
                                />
                                <polygon
                                    className="izrez-inox"
                                    points={`
                                        10,700
                                        ${this.props.doorWidth-10},900
                                        ${this.props.doorWidth-10},925
                                        10,725
                                    `}
                                />
                                <polygon
                                    className="izrez-inox"
                                    points={`
                                        10,1350
                                        ${this.props.doorWidth-10},1150
                                        ${this.props.doorWidth-10},1175
                                        10,1375
                                    `}
                                />
                                <polygon
                                    className="izrez-inox"
                                    points={`
                                        10,1600
                                        ${this.props.doorWidth-10},1800
                                        ${this.props.doorWidth-10},1825
                                        10,1625
                                    `}
                                />
                            </React.Fragment>
                            :
                            <React.Fragment>

                                <line
                                    x1="10"
                                    y1="450"
                                    x2={`${this.props.doorWidth - 10}`}
                                    y2="250"
                                    style={{stroke: "rgba(0, 0, 0, 0.35)", strokeWidth: "10"}}
                                />
                                <line
                                    x1="10"
                                    y1="475"
                                    x2={`${this.props.doorWidth - 10}`}
                                    y2="275"
                                    style={{stroke: "rgba(246, 246, 246, 0.35)", strokeWidth: "5"}}
                                />


                                <line
                                    x1="10"
                                    y1="700"
                                    x2={`${this.props.doorWidth - 10}`}
                                    y2="900"
                                    style={{stroke: "rgba(0, 0, 0, 0.35)", strokeWidth: "10"}}
                                />
                                <line
                                    x1="10"
                                    y1="725"
                                    x2={`${this.props.doorWidth - 10}`}
                                    y2="925"
                                    style={{stroke: "rgba(246, 246, 246, 0.35)", strokeWidth: "5"}}
                                />

                                <line
                                    x1="10"
                                    y1="1350"
                                    x2={`${this.props.doorWidth - 10}`}
                                    y2="1150"
                                    style={{stroke: "rgba(0, 0, 0, 0.35)", strokeWidth: "10"}}
                                />
                                <line
                                    x1="10"
                                    y1="1375"
                                    x2={`${this.props.doorWidth - 10}`}
                                    y2="1175"
                                    style={{stroke: "rgba(246, 246, 246, 0.35)", strokeWidth: "5"}}
                                />

                                <line
                                    x1="10"
                                    y1="1600"
                                    x2={`${this.props.doorWidth - 10}`}
                                    y2="1800"
                                    style={{stroke: "rgba(0, 0, 0, 0.35)", strokeWidth: "10"}}
                                />
                                <line
                                    x1="10"
                                    y1="1625"
                                    x2={`${this.props.doorWidth - 10}`}
                                    y2="1825"
                                    style={{stroke: "rgba(246, 246, 246, 0.35)", strokeWidth: "5"}}
                                />

                            </React.Fragment>
                        }

                        {this.props.handleDirection === "right" ?
                            <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="middle" x={this.props.doorWidth/2} y={250} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                            :
                            <text textAnchor="middle" x={this.props.doorWidth/2} y={250} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                        }

                    </>
                }
            </React.Fragment>
        );
    }
}

export default CER026;