import React, { Component } from "react";
import { Container, Row, Col, Button, Accordion, Card, Form, Alert, Dropdown, DropdownButton, ButtonGroup, ToggleButton, InputGroup, ListGroup, ListGroupItem, Modal } from "react-bootstrap";
// import { List, DoorOpen, Rulers, Palette, Key, Fullscreen, PlusCircleDotted, GripHorizontal } from 'react-bootstrap-icons';
import { handleDirection, inoxOdbojnaLetev, inoxAplikacije, citalecPrstnihOdtisov, skritiPanti, houseView, doorModels, frameModels, colorButtons, handleModels, doorSeries, doorLock, doorDecor, doorDimensions } from "./Constants";
import { v4 as uuidv4 } from 'uuid';

import Door from "./components/doorConfigurator/Door";
import Frame from "./components/doorConfigurator/Frame";
import Submit from "./components/Submit";
import logo from "./assets/images/logo.png";
import { ozadjeZunanje1, ozadjeZunanje2, ozadjeBelZid, ozadjeSivZid, ozadjeOpeka, ozadjeNotranje1, ozadjeNotranje2, ozadjeNotranje3 } from "./Ozadja.js";
import ImgOzadjeZunanje1 from "./assets/images/ozadje/ozadje-zunanje-1.jpg";
import ImgOzadjeZunanje2 from "./assets/images/ozadje/ozadje-zunanje-2.jpg";
import ImgOzadjeNotranje1 from "./assets/images/ozadje/ozadje-notranje-1.jpg";
import ImgOzadjeNotranje2 from "./assets/images/ozadje/ozadje-notranje-2.jpg";
import ImgOzadjeNotranje3 from "./assets/images/ozadje/ozadje-notranje-3.jpg";
import ImgOzadjeNotranjeZid from "./assets/images/ozadje/ozadje-notranje-zid.jpg";
import ImgOzadjeZunanjeBelZid from "./assets/images/ozadje/ozadje-zunanje-bel-zid.jpg";
import ImgOzadjeZunanjeSivZid from "./assets/images/ozadje/ozadje-zunanje-siv-zid.jpg";
import ImgOzadjeZunanjeOpeka from "./assets/images/ozadje/ozadje-zunanje-opeka.jpg";
import { Nav, Dropdown as DropdownRS, Message } from "rsuite";
import { Menu, ArrowDownLine } from '@rsuite/icons';
import "rsuite/dist/rsuite.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabKey: "model",
            colorsMenuOpened: false,
            model: "CER-001",
            frameModel: "01",
            dimFrameWidth: 1000,
            dimFrameHeight: 2100,
            frameWidth: 1100,
            frameHeight: 2150,
            doorWidth: 1000,
            doorHeight: 2100,
            glassWidthLeft: 0,
            glassHeightTop: 0,
            glassWidthRight: 0,
            decorSet: false,
            decor2Set: false,
            decorInsideSet: false,
            extraMetalicSet: false,
            extraMetalicSetNotranja: false,
            secondDecorSet: false,
            colorDecor1: "hrast",
            colorDecor2: "hrast",
            color: "RAL-7016",
            color2: "RAL-9016",
            colorInside: "RAL-9016-FS",
            colorDecorInside: "hrast",
            glassModel: "",
            glassFrameModel: "",
            handleDirection: "left",
            inoxOdbojnaLetev: true,
            inoxAplikacije: false,
            inoxAplikacijeMenu: false,
            skritiPanti: false,
            view: "outside",
            handleModelOutside: "rocaj-inox-800",
            handleModelInside: "kljuka-alu",
            citalecPrstnihOdtisov: "brez",
            handleMenuOutOpened: false,
            handleMenuInOpened: false,
            wallColor: "ozadje-1",
            insideLook: "ozadje-notranje-3",
            outsideLook: "ozadje-zunanje-1",
            outsideLookObj: ozadjeZunanje1,
            insideLookObj: ozadjeNotranje3,
            doorSeries: "deluxe-milenium-80",
            doorLock: "avtomatska-5-tockovna-kljucavnica",
            responsiveConfigMenuOpened: false,
            modalSerija: false,
            hisnaStevilka: "",
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        // Call our fetch function below once the component mounts
        this.callBackendAPI().then(res => console.log(res.express)).catch(err => console.log(err));
    };

    callBackendAPI = async () => {
        const response = await fetch('/express_backend');
        const body = await response.json();
    
        if (response.status !== 200) {
            throw Error(body.message) 
        }
        return body;
    };

    handleDirectionChange(event) {
	    this.setState({handleDirection: event.target.value})  
    }

    onChange(field, value) {
        this.setState({[field]: value});
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit(event) {
        event.preventDefault();
    };
    
    onFormSubmit = e => {
        e.preventDefault()
        const form = e.currentTarget;
        if (form.checkValidity() === true) {
            const formData = new FormData(e.target),
                formDataObj = Object.fromEntries(formData.entries());
                if (this.state.frameModel === "01") {
                    this.setState({
                        doorWidth: Number(formDataObj.doorWidth),
                        doorHeight: Number(formDataObj.doorHeight),
                        frameWidth: (Number(formDataObj.doorWidth)+100),
                        frameHeight: (Number(formDataObj.doorHeight)+50)});
                }
                if (this.state.frameModel === "02") {
                    this.setState({
                        dimFrameWidth: (Number(formDataObj.doorWidth)+Number(formDataObj.glassWidthRight)),
                        dimFrameHeight: (Number(formDataObj.doorHeight)),
                        doorWidth: Number(formDataObj.doorWidth),
                        doorHeight: Number(formDataObj.doorHeight),
                        frameWidth: (Number(formDataObj.doorWidth)+150+Number(formDataObj.glassWidthRight)),
                        frameHeight: (Number(formDataObj.doorHeight)+50),
                        glassWidthRight: Number(formDataObj.glassWidthRight)});
                }
                if (this.state.frameModel === "03") {
                    this.setState({
                        dimFrameWidth: (Number(formDataObj.doorWidth)+Number(formDataObj.glassWidthLeft)),
                        dimFrameHeight: (Number(formDataObj.doorHeight)),
                        doorWidth: Number(formDataObj.doorWidth),
                        doorHeight: Number(formDataObj.doorHeight),
                        frameWidth: (Number(formDataObj.doorWidth)+150+Number(formDataObj.glassWidthLeft)),
                        frameHeight: (Number(formDataObj.doorHeight)+50),
                        glassWidthLeft: Number(formDataObj.glassWidthLeft)});
                }
                if (this.state.frameModel === "04") {
                    this.setState({
                        dimFrameWidth: (Number(formDataObj.doorWidth)+Number(formDataObj.glassWidthLeft)+Number(formDataObj.glassWidthRight)),
                        dimFrameHeight: (Number(formDataObj.doorHeight)),
                        doorWidth: Number(formDataObj.doorWidth),
                        doorHeight: Number(formDataObj.doorHeight),
                        frameWidth: (Number(formDataObj.doorWidth)+200+Number(formDataObj.glassWidthLeft)+Number(formDataObj.glassWidthRight)),
                        frameHeight: (Number(formDataObj.doorHeight)+50),
                        glassWidthRight: Number(formDataObj.glassWidthRight),
                        glassWidthLeft: Number(formDataObj.glassWidthLeft)});
                }
                if (this.state.frameModel === "07") {
                    this.setState({
                        dimFrameWidth: (Number(formDataObj.doorWidth)+Number(formDataObj.glassWidthLeft)+Number(formDataObj.glassWidthRight)),
                        dimFrameHeight: (Number(formDataObj.doorHeight)+Number(formDataObj.glassHeightTop)),
                        doorWidth: Number(formDataObj.doorWidth),
                        doorHeight: Number(formDataObj.doorHeight),
                        frameWidth: (Number(formDataObj.doorWidth)+200+Number(formDataObj.glassWidthLeft)+Number(formDataObj.glassWidthRight)),
                        frameHeight: (Number(formDataObj.doorHeight)+100+Number(formDataObj.glassHeightTop)),
                        glassHeightTop: Number(formDataObj.glassHeightTop),
                        glassWidthRight: Number(formDataObj.glassWidthRight),
                        glassWidthLeft: Number(formDataObj.glassWidthLeft)});
                }
                if (this.state.frameModel === "06") {
                    this.setState({
                        dimFrameWidth: (Number(formDataObj.doorWidth)+Number(formDataObj.glassWidthLeft)),
                        dimFrameHeight: (Number(formDataObj.doorHeight)+Number(formDataObj.glassHeightTop)),
                        doorWidth: Number(formDataObj.doorWidth),
                        doorHeight: Number(formDataObj.doorHeight),
                        frameWidth: (Number(formDataObj.doorWidth)+150+Number(formDataObj.glassWidthLeft)),
                        frameHeight: (Number(formDataObj.doorHeight)+100+Number(formDataObj.glassHeightTop)),
                        glassHeightTop: Number(formDataObj.glassHeightTop),
                        glassWidthLeft: Number(formDataObj.glassWidthLeft)});
                }
                if (this.state.frameModel === "05") {
                    this.setState({
                        dimFrameWidth: (Number(formDataObj.doorWidth)+Number(formDataObj.glassWidthRight)),
                        dimFrameHeight: (Number(formDataObj.doorHeight)+Number(formDataObj.glassHeightTop)),
                        doorWidth: Number(formDataObj.doorWidth),
                        doorHeight: Number(formDataObj.doorHeight),
                        frameWidth: (Number(formDataObj.doorWidth)+150+Number(formDataObj.glassWidthRight)),
                        frameHeight: (Number(formDataObj.doorHeight)+100+Number(formDataObj.glassHeightTop)),
                        glassHeightTop: Number(formDataObj.glassHeightTop),
                        glassWidthRight: Number(formDataObj.glassWidthRight)});
                }
        }
    
    }

    render() {

        const viewWidth = (0 + Number(this.state.frameWidth));
        const viewHeight = (0 + Number(this.state.frameHeight));
        const viewBox = `0 0 ${viewWidth} ${viewHeight}`;
        const viewBox2 = `0 0 ${viewWidth*2} ${viewHeight}`;

        const tempDoorModel = doorModels.find(item => item.value === this.state.model);
        const tempFrameModel = frameModels.find(item => item.value === this.state.frameModel);
        const tempColor = colorButtons.find(item => item.value === this.state.color);
        const tempColorInside = colorButtons.find(item => item.value === this.state.colorInside);
        const tempDecor1 = doorDecor.find(item => item.value === this.state.colorDecor1);
        const tempDecor2 = doorDecor.find(item => item.value === this.state.colorDecor2);
        const tempDecorInside = doorDecor.find(item => item.value === this.state.colorDecorInside);
        const tempHandleOut = handleModels.find(item => item.handleName === this.state.handleModelOutside);
        const tempHandleIn = handleModels.find(item => item.handleName === this.state.handleModelInside);

        const frame = [<Frame
            key={uuidv4()}
            frameModel={this.state.frameModel}
            doorFrame={this.state.frameModel}
            doorModel={this.state.model}
            handleDirection={this.state.handleDirection}
            frameWidth={this.state.frameWidth}
            frameHeight={this.state.frameHeight}
            doorWidth={this.state.doorWidth}
            doorHeight={this.state.doorHeight}
            color={this.state.color}
            colorInside={this.state.colorInside}
            decorSet={this.state.decorSet}
            decorInsideSet={this.state.decorInsideSet}
            colorDecor1={this.state.colorDecor1}
            colorDecor2={this.state.colorDecor2}
            colorDecorInside={this.state.colorDecorInside}
            extraMetalicSet={this.state.extraMetalicSet}
            view={this.state.view}
            glassFrameModel={this.state.glassFrameModel}
            glassWidthRight={this.state.glassWidthRight}
            glassWidthLeft={this.state.glassWidthLeft}
            glassHeightTop={this.state.glassHeightTop}
            inoxOdbojnaLetev={this.state.inoxOdbojnaLetev}
        />];
        const door= [<Door
            key={uuidv4()}
            glassWidthRight={this.state.glassWidthRight}
            glassWidthLeft={this.state.glassWidthLeft}
            glassHeightTop={this.state.glassHeightTop}
            model={this.state.model}
            doorWidth={this.state.doorWidth}
            doorHeight={this.state.doorHeight}
            frameWidth={this.state.frameWidth}
            frameHeight={this.state.frameHeight}
            color={this.state.color}
            colorInside={this.state.colorInside}
            color2={this.state.color2}
            decorSet={this.state.decorSet}
            decorInsideSet={this.state.decorInsideSet}
            decor2Set={this.state.decor2Set}
            colorDecor1={this.state.colorDecor1}
            colorDecor2={this.state.colorDecor2}
            colorDecorInside={this.state.colorDecorInside}
            extraMetalicSet={this.state.extraMetalicSet}
            extraMetalicSetNotranja={this.state.extraMetalicSetNotranja}
            handleModelOutside={this.state.handleModelOutside}
            handleModelInside={this.state.handleModelInside}
            citalecPrstnihOdtisov={this.state.citalecPrstnihOdtisov}
            handleDirection={this.state.handleDirection}
            view={this.state.view}
            glassModel={this.state.glassModel}
            inoxOdbojnaLetev={this.state.inoxOdbojnaLetev}
            inoxAplikacije={this.state.inoxAplikacije}
            hisnaStevilka={this.state.hisnaStevilka}
            skritiPanti={this.state.skritiPanti}
        />];

        const RALcolorCategories = [];
        colorButtons.map((n) => { return !RALcolorCategories.includes(n.category) && RALcolorCategories.push(n.category) });

        return (
            <div className="main-window">
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className="navigation-top">
                            <div className="ceral-logo">
                                <img alt="Čeral logo" src={logo} />
                            </div>
                            <div className="navigation-top-tabs">
                                <DropdownRS className="hide-on-desktop" title="" activeKey={this.state.tabKey} icon={<Menu />}>
                                    <DropdownRS.Item eventKey="model" onSelect={tabKey => this.setState({ tabKey })}>Model vrat</DropdownRS.Item>
                                    <DropdownRS.Item eventKey="dimenzije" onSelect={tabKey => this.setState({ tabKey })}>Dimenzije</DropdownRS.Item>
                                    <DropdownRS.Item eventKey="barva" onSelect={tabKey => this.setState({ tabKey })}>Barva</DropdownRS.Item>
                                    { (tempDoorModel.glass || tempFrameModel.glass) && <DropdownRS.Item eventKey="steklo" onSelect={tabKey => this.setState({ tabKey })}>Steklo</DropdownRS.Item> }
                                    <DropdownRS.Item eventKey="dodatki" onSelect={tabKey => this.setState({ tabKey })}>Dodatki</DropdownRS.Item>
                                </DropdownRS>
                                <Nav className="hide-on-mobile" appearance="subtle" activeKey={this.state.tabKey} justified>
                                    <Nav.Item eventKey="model" onSelect={tabKey => this.setState({ tabKey })}>Model vrat</Nav.Item>
                                    <Nav.Item eventKey="dimenzije" onSelect={tabKey => this.setState({ tabKey })}>Dimenzije</Nav.Item>
                                    <Nav.Item eventKey="barva" onSelect={tabKey => this.setState({ tabKey })}>Barva</Nav.Item>
                                    { (tempDoorModel.glass || tempFrameModel.glass) && <Nav.Item eventKey="steklo" onSelect={tabKey => this.setState({ tabKey })}>Steklo</Nav.Item>}
                                    <Nav.Item eventKey="dodatki" onSelect={tabKey => this.setState({ tabKey })}>Dodatki</Nav.Item>
                                </Nav>
                            </div>
                        </div>
                    </Col>
                    <div className="configurator relative">
                        <div className="configurator-visual-panel">
                            <div className="buttons-wall-bg">
                                <div className="buttons-ozadje-pogled">
                                    { this.state.view === "outside" ?
                                    <DropdownButton variant="outline-secondary" id="dropdown-basic-button" title="Zunanje ozadje">
                                        <Dropdown.Header>Izberite zunanje ozadje</Dropdown.Header>
                                        <Dropdown.Item
                                            className={this.state.outsideLook === "ozadje-zunanje-1" && "active"}
                                            id="ozadje-zunanje-1"
                                            onSelect={() => this.setState({ outsideLookObj: ozadjeZunanje1, outsideLook: "ozadje-zunanje-1" })}
                                            value="Ozadje zunanje 1">
                                                <img alt="Ozadje zunanje 1" src={ImgOzadjeZunanje1} width="100px" /> Ozadje 1
                                        </Dropdown.Item>
                                        <Dropdown.Item 
                                            className={this.state.outsideLook === "ozadje-zunanje-2" && "active"}
                                            id="ozadje-zunanje-2"
                                            onSelect={() => this.setState({ outsideLookObj: ozadjeZunanje2, outsideLook: "ozadje-zunanje-2" })}
                                            value="Ozadje zunanje 2" >
                                                <img alt="Ozadje zunanje 2" src={ImgOzadjeZunanje2} width="100px" /> Ozadje 2
                                        </Dropdown.Item>
                                        <Dropdown.Item 
                                            className={this.state.outsideLook === "ozadje-zunanje-bel-zid" && "active"}
                                            id="ozadje-zunanje-bel-zid"
                                            onSelect={() => this.setState({ outsideLookObj: ozadjeBelZid, outsideLook: "ozadje-zunanje-bel-zid", insideLookObj: ozadjeBelZid, insideLook: "ozadje-notranje-zid" })}
                                            value="Ozadje zunanje bel zid" >
                                                <img alt="Ozadje zunanje bela fasada" src={ImgOzadjeZunanjeBelZid} width="100px" /> Bela fasada
                                        </Dropdown.Item>
                                        <Dropdown.Item 
                                            className={this.state.outsideLook === "ozadje-zunanje-siv-zid" && "active"} id="ozadje-zunanje-siv-zid"
                                            onSelect={() => this.setState({ outsideLookObj: ozadjeSivZid, outsideLook: "ozadje-zunanje-siv-zid", insideLookObj: ozadjeBelZid, insideLook: "ozadje-notranje-zid" })}
                                            value="Ozadje zunanje siv zid" >
                                                <img alt="Ozadje zunanje siva fasada" src={ImgOzadjeZunanjeSivZid} width="100px" /> Siva fasada
                                        </Dropdown.Item>
                                        <Dropdown.Item 
                                            className={this.state.outsideLook === "ozadje-zunanje-opeka" && "active"} id="ozadje-zunanje-opeka"
                                            onSelect={() => this.setState({ outsideLookObj: ozadjeOpeka, outsideLook: "ozadje-zunanje-opeka", insideLookObj: ozadjeBelZid, insideLook: "ozadje-notranje-zid" })}
                                            value="Ozadje zunanje opeka" >
                                                <img alt="Ozadje zunanje opeka" src={ImgOzadjeZunanjeOpeka} width="100px" /> Opeka
                                        </Dropdown.Item>
                                    </DropdownButton>
                                    :
                                    <DropdownButton variant="outline-secondary" id="dropdown-basic-button" title="Notranje ozadje">
                                        <Dropdown.Header>Izberite notranje ozadje</Dropdown.Header>
                                        <Dropdown.Item
                                            className={this.state.insideLook === "ozadje-notranje-1" && "active"}
                                            id="ozadje-notranje-1"
                                            onSelect={() => this.setState({ insideLookObj: ozadjeNotranje1, insideLook: "ozadje-notranje-1" })}
                                            value="Ozadje notranje 1">
                                                <img alt="Ozadje notranje 1" src={ImgOzadjeNotranje1} width="100px" /> Ozadje 1
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className={this.state.insideLook === "ozadje-notranje-2" && "active"}
                                            id="ozadje-notranje-2"
                                            onSelect={() => this.setState({ insideLookObj: ozadjeNotranje2, insideLook: "ozadje-notranje-2" })}
                                            value="Ozadje notranje 2">
                                                <img alt="Ozadje notranje 2" src={ImgOzadjeNotranje2} width="100px" /> Ozadje 2
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className={this.state.insideLook === "ozadje-notranje-3" && "active"}
                                            id="ozadje-notranje-3"
                                            onSelect={() => this.setState({ insideLookObj: ozadjeNotranje3, insideLook: "ozadje-notranje-3" })}
                                            value="Ozadje notranje 3">
                                                <img alt="Ozadje notranje 3" src={ImgOzadjeNotranje3} width="100px" /> Ozadje 3
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className={this.state.insideLook === "ozadje-notranje-zid" && "active"}
                                            id="ozadje-notranje-zid"
                                            onSelect={() => this.setState({ insideLookObj: ozadjeBelZid, insideLook: "ozadje-notranje-zid" })}
                                            value="Ozadje notranje zid">
                                                <img alt="Ozadje notranje stena" src={ImgOzadjeNotranjeZid} width="100px" /> Stena
                                        </Dropdown.Item>
                                    </DropdownButton>
                                    }

                                    <ButtonGroup className="" style={{margin: "0 1rem"}} toggle>
                                        {houseView.map((radio, idx) => (
                                        <ToggleButton
                                            key={idx}
                                            type="radio"
                                            variant="outline-secondary"
                                            name="radio"
                                            value={radio.value}
                                            checked={this.state.view === radio.value}
                                            onChange={() => this.setState({ view: radio.value })}
                                        >
                                            {radio.name}
                                        </ToggleButton>
                                        ))}
                                    </ButtonGroup>
                                </div>

                                <div className="responsive-navigation hide-on-desktop">
                                {this.state.tabKey === "model" &&
                                    <div onClick={() => this.setState({responsiveConfigMenuOpened: !this.state.responsiveConfigMenuOpened})} className="button-flex">
                                        <h4>Model vrat</h4>
                                        <ArrowDownLine className="icon-size-2" />
                                    </div>}
                                {this.state.tabKey === "dimenzije" &&
                                    <div onClick={() => this.setState({responsiveConfigMenuOpened: !this.state.responsiveConfigMenuOpened})} className="button-flex">
                                        <h4>Dimenzije</h4>
                                        <ArrowDownLine className="icon-size-2" />
                                    </div>}
                                {this.state.tabKey === "barva" && 
                                    <div onClick={() => this.setState({responsiveConfigMenuOpened: !this.state.responsiveConfigMenuOpened})} className="button-flex">
                                        <h4>Barva</h4>
                                        <ArrowDownLine className="icon-size-2" />
                                    </div>}
                                {this.state.tabKey === "steklo" &&
                                    <div onClick={() => this.setState({responsiveConfigMenuOpened: !this.state.responsiveConfigMenuOpened})} className="button-flex">
                                        <h4>Steklo</h4>
                                        <ArrowDownLine className="icon-size-2" />
                                    </div>}
                                {this.state.tabKey === "dodatki" &&
                                    <div onClick={() => this.setState({responsiveConfigMenuOpened: !this.state.responsiveConfigMenuOpened})} className="button-flex">
                                        <h4>Dodatki</h4>
                                        <ArrowDownLine className="icon-size-2" />
                                    </div>}
                                </div>  
                            </div>
                            <Message className="hide-on-mobile" style={{position: "absolute", right: "0", marginRight: "1rem", marginTop: "1rem", width: "15rem", zIndex: "10"}} showIcon type="info" closable>
                                { this.state.frameModel === "01" ?
                                <React.Fragment>
                                    <b>Izbran model:</b> {tempDoorModel.doorName} <br />
                                    <b>Skupna širina:</b> {this.state.doorWidth} mm <br />
                                    <b>Skupna višina:</b> {this.state.doorHeight} mm <br />
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <b>Izbran model:</b> {tempDoorModel.doorName} <br />
                                    <b>Skupna širina:</b> {this.state.dimFrameWidth} mm <br />
                                    <b>Skupna višina:</b> {this.state.dimFrameHeight} mm <br />
                                    <b>Širina vrat:</b> {this.state.doorWidth} mm <br />
                                    <b>Višina vrat:</b> {this.state.doorHeight} mm <br />
                                </React.Fragment>
                                }
                            </Message>
                            <div className="door-visual__grid" style={this.state.view === "outside" ? this.state.outsideLookObj.zid : this.state.insideLookObj.belZid}>
                                <div className="door-visual__row door-visual__row-top">
                                    <div
                                        className={`door-visual__panel ${this.state.view === "outside" ? this.state.outsideLook : this.state.insideLook} A1`}
                                        style={this.state.view === "outside" ? this.state.outsideLookObj.a1 : this.state.insideLookObj.a1}
                                    ></div>
                                    <div
                                        className={`door-visual__panel ${this.state.view === "outside" ? this.state.outsideLook : this.state.insideLook} B1`}
                                        style={this.state.view === "outside" ? this.state.outsideLookObj.b1 : this.state.insideLookObj.b1}
                                    ></div>
                                    <div
                                        className={`door-visual__panel ${this.state.view === "outside" ? this.state.outsideLook : this.state.insideLook} C1`}
                                        style={this.state.view === "outside" ? this.state.outsideLookObj.c1 : this.state.insideLookObj.c1}
                                    ></div>
                                </div>
                                <div className="door-visual__row door-visual__row-middle">
                                    <div
                                        className={`door-visual__panel ${this.state.view === "outside" ? this.state.outsideLook : this.state.insideLook} A2`}
                                        style={this.state.view === "outside" ? this.state.outsideLookObj.a2 : this.state.insideLookObj.a2}
                                        ></div>
                                    <div
                                        className={`door-visual__panel ${this.state.view === "outside" ? this.state.outsideLook : this.state.insideLook} B2`}
                                        style={{
                                            width: this.state.frameWidth/5,
                                            height: (this.state.frameHeight-75)/5}
                                        }
                                    >
                                        <div className="door-container">
                                            <svg id="vrata-konfiguracija" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox={viewBox}  >
                                                <g transform={this.state.view === "inside" ? `scale(-1,1)` : "scale(1,1)"} transform-origin="center">
                                                    {frame}
                                                    {door}
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                    <div 
                                        className={`door-visual__panel ${this.state.view === "outside" ? this.state.outsideLook : this.state.insideLook} C2`}
                                        style={this.state.view === "outside" ? this.state.outsideLookObj.c2 : this.state.insideLookObj.c2}
                                    ></div>
                                </div>
                                <div className="door-visual__row door-visual__row-bottom" style={this.state.view === "outside" ? this.state.outsideLookObj.tlaZunaj : this.state.insideLookObj.tlaNotri}>
                                    <div 
                                        className={`door-visual__panel ${this.state.view === "outside" ? this.state.outsideLook : this.state.insideLook} A3`}
                                        style={this.state.view === "outside" ? this.state.outsideLookObj.a3 : this.state.insideLookObj.a3}
                                    ></div>
                                    <div 
                                        className={`door-visual__panel ${this.state.view === "outside" ? this.state.outsideLook : this.state.insideLook} B3`}
                                        style={this.state.view === "outside" ? this.state.outsideLookObj.b3 : this.state.insideLookObj.b3}
                                    ></div> 
                                    <div 
                                        className={`door-visual__panel ${this.state.view === "outside" ? this.state.outsideLook : this.state.insideLook} C3`}
                                        style={this.state.view === "outside" ? this.state.outsideLookObj.c3 : this.state.insideLookObj.c3}
                                    ></div>
                                </div>                                
                            </div>
                            <div hidden>
                                <canvas>
                                <svg id="SVGvrataInOut" x="0" y="0" viewBox={viewBox2} width={`${(this.state.frameWidth*2+200)/10}px`} height={`${this.state.frameHeight/10}px`}>
                                    <svg id="vrataOut" x={0} y="0">
                                        <g>
                                            <Door
                                                key={uuidv4()}
                                                glassWidthRight={this.state.glassWidthRight}
                                                glassWidthLeft={this.state.glassWidthLeft}
                                                glassHeightTop={this.state.glassHeightTop}
                                                model={this.state.model}
                                                doorWidth={this.state.doorWidth}
                                                doorHeight={this.state.doorHeight}
                                                frameWidth={this.state.frameWidth}
                                                frameHeight={this.state.frameHeight}
                                                color={this.state.color}
                                                color2={this.state.color2}
                                                decorSet={this.state.decorSet}
                                                decor2Set={this.state.decor2Set}
                                                decorInsideSet={this.state.decorInsideSet}
                                                colorDecor1={this.state.colorDecor1}
                                                colorDecor2={this.state.colorDecor2}
                                                colorDecorInside={this.state.colorDecorInside}
                                                extraMetalicSet={this.state.extraMetalicSet}
                                                handleModelOutside={this.state.handleModelOutside}
                                                handleModelInside={this.state.handleModelInside}
                                                citalecPrstnihOdtisov={this.state.citalecPrstnihOdtisov}
                                                handleDirection={this.state.handleDirection}
                                                view={"outside"}
                                                glassModel={this.state.glassModel}
                                                inoxOdbojnaLetev={this.state.inoxOdbojnaLetev}
                                                inoxAplikacije={this.state.inoxAplikacije}
                                                hisnaStevilka={this.state.hisnaStevilka}
                                                skritiPanti={this.state.skritiPanti}
                                            />
                                            <Frame
                                                key={uuidv4()}
                                                frameModel={this.state.frameModel}
                                                doorFrame={this.state.frameModel}
                                                doorModel={this.state.model}
                                                handleDirection={this.state.handleDirection}
                                                frameWidth={this.state.frameWidth}
                                                frameHeight={this.state.frameHeight}
                                                doorWidth={this.state.doorWidth}
                                                doorHeight={this.state.doorHeight}
                                                color={this.state.color}
                                                decorSet={this.state.decorSet}
                                                decorInsideSet={this.state.decorInsideSet}
                                                colorDecor1={this.state.colorDecor1}
                                                colorDecor2={this.state.colorDecor2}
                                                colorDecorInside={this.state.colorDecorInside}
                                                extraMetalicSet={this.state.extraMetalicSet}
                                                view={"outside"}
                                                glassFrameModel={this.state.glassFrameModel}
                                                glassWidthRight={this.state.glassWidthRight}
                                                glassWidthLeft={this.state.glassWidthLeft}
                                                glassHeightTop={this.state.glassHeightTop}
                                                inoxOdbojnaLetev={this.state.inoxOdbojnaLetev}
                                            />
                                        </g>
                                    </svg>
                                    <svg id="vrataIn" x={0} y="0">
                                        <g transform={"scale(-1,1)"} transform-origin="center" >
                                            <Door
                                                key={uuidv4()}
                                                glassWidthRight={this.state.glassWidthRight}
                                                glassWidthLeft={this.state.glassWidthLeft}
                                                glassHeightTop={this.state.glassHeightTop}
                                                model={this.state.model}
                                                doorWidth={this.state.doorWidth}
                                                doorHeight={this.state.doorHeight}
                                                frameWidth={this.state.frameWidth}
                                                frameHeight={this.state.frameHeight}
                                                color={this.state.color}
                                                colorInside={this.state.colorInside}
                                                color2={this.state.color2}
                                                decorSet={this.state.decorSet}
                                                decor2Set={this.state.decor2Set}
                                                decorInsideSet={this.state.decorInsideSet}
                                                colorDecor1={this.state.colorDecor1}
                                                colorDecor2={this.state.colorDecor2}
                                                colorDecorInside={this.state.colorDecorInside}
                                                extraMetalicSet={this.state.extraMetalicSet}
                                                extraMetalicSetNotranja={this.state.extraMetalicSetNotranja}
                                                handleModelOutside={this.state.handleModelOutside}
                                                handleModelInside={this.state.handleModelInside}
                                                citalecPrstnihOdtisov={this.state.citalecPrstnihOdtisov}
                                                handleDirection={this.state.handleDirection}
                                                view={"inside"}
                                                glassModel={this.state.glassModel}
                                                inoxOdbojnaLetev={this.state.inoxOdbojnaLetev}
                                                inoxAplikacije={this.state.inoxAplikacije}
                                                hisnaStevilka={this.state.hisnaStevilka}
                                                skritiPanti={this.state.skritiPanti}
                                            />
                                            <Frame
                                                key={uuidv4()}
                                                frameModel={this.state.frameModel}
                                                doorFrame={this.state.frameModel}
                                                doorModel={this.state.model}
                                                handleDirection={this.state.handleDirection}
                                                frameWidth={this.state.frameWidth}
                                                frameHeight={this.state.frameHeight}
                                                doorWidth={this.state.doorWidth}
                                                doorHeight={this.state.doorHeight}
                                                color={this.state.color}
                                                colorInside={this.state.colorInside}
                                                colorDecorInside={this.state.colorDecorInside}
                                                decorSet={this.state.decorSet}
                                                decorInsideSet={this.state.decorInsideSet}
                                                colorDecor1={this.state.colorDecor1}
                                                colorDecor2={this.state.colorDecor2}
                                                extraMetalicSet={this.state.extraMetalicSet}
                                                extraMetalicSetNotranja={this.state.extraMetalicSetNotranja}
                                                view={"inside"}
                                                glassFrameModel={this.state.glassFrameModel}
                                                glassWidthRight={this.state.glassWidthRight}
                                                glassWidthLeft={this.state.glassWidthLeft}
                                                glassHeightTop={this.state.glassHeightTop}
                                                inoxOdbojnaLetev={this.state.inoxOdbojnaLetev}
                                            />
                                        </g>
                                    </svg>
                                </svg>
                                </canvas>
                            </div>
                            <div className="button-povprasevanje">
                                <Submit
                                    configuration={this.state}
                                />
                            </div>
                            <div className="footer">
                                <div>
                                    <a href="https://www.ceral.si" target="_blank" rel="noreferrer">&copy; ČERAL ing., d. o. o.</a>
                                </div>
                                <div>
                                    Izdelava spletne aplikacije: <a href="https://dpoint.si" target="_blank" rel="noreferrer">dpoint.si</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.state.responsiveConfigMenuOpened ? "responsiveConfigMenuOpened" : "configuration-menu"} >
                            <div className="configuratorSidebar">
                                { this.state.tabKey === "model" && 
                                <div>
                                        <h3>Model vrat</h3>
                                        {doorModels.map((e, index) => {
                                            return <Button
                                                key={index}
                                                variant="light"
                                                className="button-door-models"
                                                onClick={() => this.setState({
                                                    model: e.value,
                                                    handleModelOutside: e.defaultHandle,
                                                    inoxAplikacije: e.inoxAplikacije,
                                                    inoxAplikacijeMenu: e.inoxAplikacijeMenu,
                                                    glassModel: e.glassDefaultModel,
                                                    glassFrameModel: e.glassFrameDefaultModel,
                                                    decor2Set: e.secondDecorSet,
                                                    secondDecorSet: e.secondDecorSet,
                                                    colorDecor2: e.secondDecorDefault,
                                                    color: e.primaryColorDefault,
                                                    decorSet: e.primaryDecorSet,
                                                    extraMetalicSet: false
                                                })}
                                                active={e.value === this.state.model}
                                            >
                                                <img alt={`Model vrat: ${e.value}`} src={e.img} height="140px;" />
                                                <br />
                                                <p>{e.doorName}</p>
                                            </Button>
                                        })}

                                        <hr />

                                        <h3>Izvedba vrat</h3>
                                        <div className="button-frame-models-group">
                                            {frameModels.map((e) => {
                                                return <Button
                                                    key={uuidv4()}
                                                    variant="light"
                                                    className="button-frame-models"
                                                    onClick={() => this.setState({
                                                        frameModel: e.value,
                                                        handleDirection: e.defaultHandleDirection,
                                                        frameWidth: e.frameWidth,
                                                        frameHeight: e.frameHeight,
                                                        doorWidth: e.doorWidth,
                                                        doorHeight: e.doorHeight,
                                                        glassWidthRight: e.glassRight,
                                                        glassWidthLeft: e.glassLeft,
                                                        glassHeightTop: e.glassTop,
                                                        glassFrameModel: tempDoorModel.glassFrameDefaultModel,
                                                        dimFrameHeight: e.glassTop + e.doorHeight,
                                                        dimFrameWidth: e.doorWidth + e.glassLeft + e.glassRight,
                                                    })}
                                                    active={e.value === this.state.frameModel}
                                                >
                                                    <img alt={`Obsvetloba: ${e.frameName}`} src={e.img} />
                                                    <br />
                                                    <p>{e.frameName}</p>
                                                </Button>
                                            })}
                                        </div>
                                        <hr />

                                        <h3>Način odpiranja</h3>
                                        <ButtonGroup toggle>
                                            {handleDirection.map((radio, idx) => (
                                            <ToggleButton
                                                key={idx}
                                                type="radio"
                                                variant="outline-secondary"
                                                name="radio"
                                                value={radio.value}
                                                checked={this.state.handleDirection === radio.value}
                                                onChange={() => this.setState({ handleDirection: radio.value })}
                                            >
                                                {radio.name}
                                            </ToggleButton>
                                            ))}
                                        </ButtonGroup>

                                        <hr />

                                        <h3>Serija</h3>

                                        <ButtonGroup toggle>
                                            {doorSeries.map((e, i) => (
                                            <ToggleButton
                                                key={i}
                                                type="radio"
                                                variant="outline-secondary"
                                                name="radio"
                                                value={e.value}
                                                checked={this.state.doorSeries === e.value}
                                                onChange={() => this.setState({ doorSeries: e.value })}
                                            >
                                                {e.name}
                                            </ToggleButton>
                                            ))}
                                        </ButtonGroup>
                                        <div style={{marginTop: "1rem"}}>
                                            <Button variant="primary" onClick={() => this.setState({ modalSerija: true })}>
                                                Več o serijah
                                            </Button>
                                        </div>

                                        <Modal
                                            show={this.state.modalSerija}
                                            onHide={() => this.setState({ modalSerija: false })}
                                            size="lg"
                                            centered
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title>Serije</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div style={{marginTop: "1rem"}}>
                                                    <ListGroup>
                                                        <ListGroupItem>
                                                            <h5>Serija deluxe milenium 80</h5>
                                                            <img width="40%" style={{float: "left"}} alt="Serija deluxe milenium 80" src="assets/images/serija/deluxe-milenium-80.jpg" /> 
                                                            <p>Vhodna vrata serije deluxe milenium 80 so izdelana po najvišjih standardih. Primerna so za vgradnjo v stanovanjske in poslovne objekte. Omogočena je maksimalna 4-slojna zasteklitev v skupni debelini 60 mm. Deluxe milenium 80 vhodna vrata dosegajo visoko zvočno in toplotno izolacijo s trojnim tesnenjem.</p>
                                                        </ListGroupItem>
                                                        <ListGroupItem>Toplotna prehodnost: <br /> U<sub>d</sub> = 0.8 W/m<sup>2</sup>K</ListGroupItem>
                                                        <ListGroupItem>Zvočna prehodnost: <br /> R<sub>w</sub> = 40 dB</ListGroupItem>
                                                    </ListGroup>
                                                </div>

                                                <div style={{marginTop: "1rem"}}>
                                                    <ListGroup>
                                                        <ListGroupItem>
                                                            <h5>Serija premium 120</h5>
                                                            <img width="40%" style={{float: "left"}} alt="Serija premium 120" src="assets/images/serija/premium-120.jpg" /> 
                                                            <p>Vhodna vrata serije premium 120 so izdelana po najvišjih standardih. Primerna so za vgradnjo v stanovanjske in poslovne objekte. Zaradi odličnih toplotnih in zvočnih karakteristik so vrata primerna tudi za pasivno gradnjo. Omogočena je maksimalna 4-slojna zasteklitev v skupni debelini 103mm. Premium 120 vhodna vrata dosegajo visoko zvočno in toplotno izolacijo s štiri kratnim tesnenjem.</p>
                                                        </ListGroupItem>
                                                        <ListGroupItem>Toplotna prehodnost: <br /> U<sub>d</sub> = 0.61-0.64 W/m<sup>2</sup>K</ListGroupItem>
                                                        <ListGroupItem>Najvišji protivlomni razred RC3</ListGroupItem>
                                                        <ListGroupItem>Skriti panti</ListGroupItem>
                                                    </ListGroup>
                                                </div>
                                                <Button style={{marginTop: "1rem"}} variant="primary" href="https://www.ceral.si/vhodna-vrata/" target="_blank" >Več o serijah</Button>
                                                
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={() => this.setState({ modalSerija: false })}>
                                                    Zapri
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                        <hr />

                                        <h3>Skriti panti</h3>

                                        <ButtonGroup toggle>
                                            {skritiPanti.map((e, i) => (
                                            <ToggleButton
                                                key={i}
                                                type="radio"
                                                variant="outline-secondary"
                                                name="radio"
                                                value={e.value}
                                                checked={this.state.skritiPanti === e.value}
                                                onChange={() => this.setState({ skritiPanti: e.value })}
                                            >
                                                {e.name}
                                            </ToggleButton>
                                            ))}
                                        </ButtonGroup>


                                    </div>}
                                    { this.state.tabKey === "dimenzije" &&
                                    <div>
                                        <h3>Dimenzije vrat</h3>
                                        
                                        <Form noValidate validated="true" onSubmit={this.onFormSubmit}>
                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>Širina vrat</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control name="doorWidth" min={doorDimensions.defaultDoorMinWidth} max={doorDimensions.defaultDoorMaxWidth} defaultValue={this.state.doorWidth} type="number"/>
                                                        <InputGroup.Append>
                                                            <InputGroup.Text id="doorwidthmm">mm</InputGroup.Text>
                                                        </InputGroup.Append>
                                                        <Form.Control.Feedback type="invalid">
                                                        Najmanjša širina je {doorDimensions.defaultDoorMinWidth} mm, največja pa {doorDimensions.defaultDoorMaxWidth} mm.
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label>Višina vrat</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control name="doorHeight" min={doorDimensions.defaultDoorMinHeight} max={doorDimensions.defaultDoorMaxHeight} defaultValue={this.state.doorHeight} type="number" />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text id="doorheightmm">mm</InputGroup.Text>
                                                        </InputGroup.Append>
                                                        <Form.Control.Feedback type="invalid">
                                                        Najmanjša višina je {doorDimensions.defaultDoorMinHeight} mm, največja pa {doorDimensions.defaultDoorMaxHeight} mm.
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Form.Row>
                                            <hr />

                                            {(this.state.frameModel === "02" || this.state.frameModel === "05" || this.state.frameModel === "04" || this.state.frameModel === "07") && 
                                            <React.Fragment>
                                                <Form.Row>
                                                    <Form.Group as={Col}>
                                                        <Form.Label>Širina desne obsvetlobe</Form.Label>
                                                        <InputGroup>
                                                            <Form.Control name="glassWidthRight" min={doorDimensions.minObWidth} max={doorDimensions.maxObWidth} defaultValue={this.state.glassWidthRight} type="number"/>
                                                            <InputGroup.Append>
                                                                <InputGroup.Text id="glassWidthRightmm">mm</InputGroup.Text>
                                                            </InputGroup.Append>
                                                            <Form.Control.Feedback type="invalid">
                                                            Najmanjša širina stekla je {doorDimensions.minObWidth} mm, največja pa {doorDimensions.maxObWidth} mm.
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Form.Row>
                                                <hr />
                                            </React.Fragment>
                                            }
                                            {(this.state.frameModel === "03" || this.state.frameModel === "04" || this.state.frameModel === "07" || this.state.frameModel === "06") &&
                                            <React.Fragment>
                                                <Form.Row>
                                                    <Form.Group as={Col}>
                                                        <Form.Label>Širina leve obsvetlobe</Form.Label>
                                                        <InputGroup>
                                                            <Form.Control name="glassWidthLeft" min={doorDimensions.minObWidth} max={doorDimensions.maxObWidth} defaultValue={this.state.glassWidthLeft} type="number"/>
                                                            <InputGroup.Append>
                                                                <InputGroup.Text id="glassWidthLeftmm">mm</InputGroup.Text>
                                                            </InputGroup.Append>
                                                            <Form.Control.Feedback type="invalid">
                                                            Najmanjša širina stekla je {doorDimensions.minObWidth} mm, največja pa {doorDimensions.maxObWidth} mm.
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Form.Row>
                                                <hr />
                                            </React.Fragment>
                                            }
                                            {(this.state.frameModel === "07" || this.state.frameModel === "06" || this.state.frameModel === "05" || this.state.frameModel === "06") && 
                                            <React.Fragment>
                                                <Form.Row>
                                                    <Form.Group as={Col}>
                                                        <Form.Label>Višina nadsvetlobe</Form.Label>
                                                        <InputGroup>
                                                            <Form.Control name="glassHeightTop" min={doorDimensions.minObHeight} max={doorDimensions.maxObHeight} defaultValue={this.state.glassHeightTop} type="number"/>
                                                            <InputGroup.Append>
                                                                <InputGroup.Text id="glassHeightTopmm">mm</InputGroup.Text>
                                                            </InputGroup.Append>
                                                            <Form.Control.Feedback type="invalid">
                                                            Najmanjša širina stekla je {doorDimensions.minObHeight} mm, največja pa {doorDimensions.maxObHeight} mm.
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Form.Row>
                                                <hr />
                                            </React.Fragment>
                                            }

                                            <Button type="submit">Nariši vrata</Button>
                                        </Form>
                                        <hr />
                                        <Alert variant="info">
                                            <Alert.Heading>Izbrane dimenzije</Alert.Heading>
                                            <p>
                                                Širina vrat: {this.state.doorWidth} mm<br />
                                                Višina vrat: {this.state.doorHeight} mm<br />
                                            </p> 
                                            <hr />
                                            {this.state.frameModel !== "01" && 
                                            <p>
                                                Širina okvirja: {this.state.dimFrameWidth} mm<br />
                                                Višina okvirja: {this.state.dimFrameHeight} mm<br />
                                            </p>}
                                        </Alert>
                                        
                                    </div> }
                                    { this.state.tabKey === "barva" &&
                                    <div>
                                        { !this.state.colorsMenuOpened &&
                                            <div>
                                                { tempDoorModel.primaryColorOptions === "color-decor" &&
                                                    <React.Fragment>
                                                        <Alert variant="success">
                                                            Izberite barvo ali dekor krila.
                                                        </Alert>
                                                        <h4>Zunanja barva ali dekor krila</h4>
                                                        <div className="colors-button-group">
                                                            <div
                                                                className="button-color-option"
                                                                onClick={() => this.setState({ colorsMenuOpened: "primarna-barva" })}
                                                            >
                                                                BARVA
                                                            </div>
                                                            <div
                                                                className="button-color-option"
                                                                onClick={() => this.setState({ colorsMenuOpened: "dekor1" })}
                                                            >
                                                                DEKOR
                                                            </div>
                                                        </div>  
                                                        <h4>Notranja barva ali dekor krila</h4>
                                                        <div className="colors-button-group">
                                                            <div
                                                                className="button-color-option"
                                                                onClick={() => this.setState({ colorsMenuOpened: "primarna-barva-notranja" })}
                                                            >
                                                                BARVA
                                                            </div>
                                                            <div
                                                                className="button-color-option"
                                                                onClick={() => this.setState({ colorsMenuOpened: "dekor-notranja" })}
                                                            >
                                                                DEKOR
                                                            </div>
                                                        </div>  
                                                    </React.Fragment>
                                                }
                                                { tempDoorModel.primaryColorOptions === "color" &&
                                                    <React.Fragment>
                                                        <Alert variant="success">
                                                            Izberite barvo
                                                        </Alert>
                                                        <h4>Barva krila</h4>
                                                        <div className="colors-button-group">
                                                            <div
                                                                className="button-color-option"
                                                                onClick={() => this.setState({ colorsMenuOpened: "primarna-barva" })}
                                                            >
                                                                BARVA
                                                            </div>
                                                        </div>  
                                                    </React.Fragment>
                                                }
                                            </div>
                                        }

                                        { !this.state.colorsMenuOpened && tempDoorModel.secondDecorSet &&
                                        <div className="">
                                            <h4>Dekor element</h4>
                                            <div className="colors-button-group">
                                                {/* <div
                                                    className="button-color-option"
                                                    onClick={() => this.setState({ colorsMenuOpened: "sekundarna-barva" })}
                                                >
                                                    BARVA
                                                </div> */}
                                                <div
                                                    className="button-color-option"
                                                    onClick={() => this.setState({ colorsMenuOpened: "dekor2" })}
                                                >
                                                    DEKOR
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        
                                        
                                        { this.state.colorsMenuOpened === "primarna-barva" &&
                                            <div>
                                                <Button className="button-nazaj" variant="outline-dark" onClick={() => this.setState({ colorsMenuOpened: false })} >&larr; Nazaj</Button>
                                                <h4>Primarna zunanja barva</h4>
                                                <Accordion defaultActiveKey={1}>
                                                    {RALcolorCategories.map((i, indx) => {
                                                        return (
                                                            <Card key={indx}>
                                                                <Accordion.Toggle as={Card.Header} variant="link" eventKey={indx+1}>
                                                                    {i}
                                                                </Accordion.Toggle>
                                                                <Accordion.Collapse eventKey={indx+1}>
                                                                    <Card.Body>
                                                                    <h3>{i}</h3>
                                                                    {(colorButtons.filter(item => item.category === i)).map((e, idx) => {
                                                                            return  <Button
                                                                                    key={idx}
                                                                                    className={`${e.className} button-color ${this.state.color === e.value && "button-color-active"}`}
                                                                                    variant="outline-secondary"
                                                                                    onClick={() => this.setState({ color: e.value, decorSet: false, extraMetalicSet: e.category === "Extra metalic" ? true : false })}
                                                                                    style={{backgroundColor: e.style}}
                                                                                >
                                                                                    {e.colorName}<br />{e.colorDescription}
                                                                                </Button>
                                                                    })}
                                                                    
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>)
                                                        })}
                                                </Accordion>
                                            </div>
                                        }
                                        { this.state.colorsMenuOpened === "primarna-barva-notranja" &&
                                            <div>
                                                <Button className="button-nazaj" variant="outline-dark" onClick={() => this.setState({ colorsMenuOpened: false })} >&larr; Nazaj</Button>
                                                <h4>Primarna notranja barva</h4>
                                                <Accordion defaultActiveKey={1}>
                                                    {RALcolorCategories.map((i, indx) => {
                                                        return (
                                                            <Card key={indx}>
                                                                <Accordion.Toggle as={Card.Header} variant="link" eventKey={indx+1}>
                                                                    {i}
                                                                </Accordion.Toggle>
                                                                <Accordion.Collapse eventKey={indx+1}>
                                                                    <Card.Body>
                                                                    <h3>{i}</h3>
                                                                    {(colorButtons.filter(item => item.category === i)).map((e, idx) => {
                                                                            return  <Button
                                                                                    key={idx}
                                                                                    className={`${e.className} button-color ${this.state.colorInside === e.value && "button-color-active"}`}
                                                                                    variant="outline-secondary"
                                                                                    onClick={() => this.setState({ colorInside: e.value, decorInsideSet: false, extraMetalicSetNotranja: e.category === "Extra metalic" ? true : false })}
                                                                                    style={{backgroundColor: e.style}}
                                                                                >
                                                                                    {e.colorName}<br />{e.colorDescription}
                                                                                </Button>
                                                                    })}
                                                                    
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>)
                                                        })}
                                                </Accordion>
                                            </div>
                                        }

                                        { this.state.colorsMenuOpened === "sekundarna-barva" &&
                                            <div>
                                                <div>
                                                    <Button className="button-nazaj" variant="outline-dark" onClick={() => this.setState({ colorsMenuOpened: false })}  >&larr; Nazaj</Button>
                                                    <h4>Sekundarna barva</h4>
                                                    <Accordion defaultActiveKey={1}>
                                                        {RALcolorCategories.map((i, indx) => {
                                                            return (
                                                                <Card key={indx}>
                                                                    <Accordion.Toggle as={Card.Header} variant="link" eventKey={indx+1}>
                                                                        {i}
                                                                    </Accordion.Toggle>
                                                                    <Accordion.Collapse eventKey={indx+1}>
                                                                        <Card.Body>
                                                                        <h3>{i}</h3>
                                                                        {(colorButtons.filter(item => item.category === i)).map((e, idx) => {
                                                                                return  <Button
                                                                                        key={idx}
                                                                                        className={`${e.className} button-color`}
                                                                                        variant="outline-secondary"
                                                                                        onClick={() => this.setState({ color2: e.value, decor2Set: false })}
                                                                                        style={{backgroundColor: e.style}}
                                                                                    >
                                                                                        {e.colorName}<br />{e.colorDescription}
                                                                                    </Button>
                                                                        })}
                                                                        
                                                                        </Card.Body>
                                                                    </Accordion.Collapse>
                                                                </Card>)
                                                        })}
                                                    </Accordion>
                                                </div>
                                            </div>
                                        }

                                        { this.state.colorsMenuOpened === "dekor1" &&
                                            <div>
                                                <div>
                                                    <Button className="button-nazaj" variant="outline-dark" onClick={() => this.setState({ colorsMenuOpened: false })}  >&larr; Nazaj</Button>
                                                    <h4>Dekor</h4>
                                                    <div className="decor-colors-group">
                                                        {doorDecor.map((e) => {
                                                            return <div
                                                                key={uuidv4()}
                                                                className="decor-colors-button"
                                                                onClick={() => this.setState({ colorDecor1: e.value, decorSet: true })}
                                                                style={{backgroundImage: `url("${e.img}"`}}>
                                                                {e.decorName}
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        { this.state.colorsMenuOpened === "dekor-notranja" &&
                                            <div>
                                                <div>
                                                    <Button className="button-nazaj" variant="outline-dark" onClick={() => this.setState({ colorsMenuOpened: false })}  >&larr; Nazaj</Button>
                                                    <h4>Dekor</h4>
                                                    <div className="decor-colors-group">
                                                        {doorDecor.map((e) => {
                                                            return <div
                                                                key={uuidv4()}
                                                                className="decor-colors-button"
                                                                onClick={() => this.setState({ colorDecorInside: e.value, decorInsideSet: true })}
                                                                style={{backgroundImage: `url("${e.img}"`}}>
                                                                {e.decorName}
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        { this.state.colorsMenuOpened === "dekor2" &&
                                            <div>
                                                <div>
                                                    <Button className="button-nazaj" variant="outline-dark" onClick={() => this.setState({ colorsMenuOpened: false })}  >&larr; Nazaj</Button>
                                                    <h4>Dekor</h4>
                                                    <div className="decor-colors-group">
                                                        {doorDecor.map((e) => {
                                                            return <div
                                                                key={uuidv4()}
                                                                className="decor-colors-button"
                                                                onClick={() => this.setState({ colorDecor2: e.value, decor2Set: true })}
                                                                style={{backgroundImage: `url("${e.img}"`}}>
                                                                {e.decorName}
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        
                                        <div>
                                            <hr />
                                            <h4 style={{marginBottom: "2rem"}}>Izbrana zunanja barva oz. dekor krila:</h4>

                                            <div className="izbrana-barva-dekor">
                                                <h5> { this.state.decorSet ? `Dekor: ${tempDecor1.decorName}` : `Barva: ${tempColor.colorName} - ${tempColor.colorDescription}`} </h5>
                                                <div className="colors-preview" style={ !this.state.decorSet ? { backgroundColor: tempColor.style} : {backgroundImage: `url("${tempDecor1.img}"` } }></div>
                                            </div>

                                            <hr />
                                            { tempDoorModel.secondDecorSet && (
                                                <div className="izbrana-barva-dekor">
                                                    <h5>Dekor element: {tempDecor2.decorName}</h5>
                                                    <div className="colors-preview" style={{backgroundImage: `url("${tempDecor2.img}"` }}></div>
                                                </div>
                                            )}
                                            
                                            <h4 style={{marginBottom: "2rem"}}>Izbrana notranja barva oz. dekor krila:</h4>

                                            <div className="izbrana-barva-dekor">
                                                <h5>{ this.state.decorInsideSet ? `Dekor: ${tempDecorInside.decorName}` : `Barva: ${tempColorInside.colorName} - ${tempColorInside.colorDescription}`} </h5>
                                                <div className="colors-preview" style={ !this.state.decorInsideSet ? { backgroundColor: tempColorInside.style} : {backgroundImage: `url("${tempDecorInside.img}"` } }></div>
                                            </div>
                                            <hr />
                                            
                                        </div>
                                                     
                                    </div> }
                                    { this.state.tabKey === "steklo" && 
                                    <div>
                                        { tempDoorModel.glass &&
                                            <>
                                                <h3>Steklo na vratih</h3>                                       
                                                <div className="stekla">
                                                    {tempDoorModel.glassModels.map((e) => {
                                                        return (
                                                            <React.Fragment key={uuidv4()}>
                                                                <div
                                                                    className={this.state.glassModel === e.model ? "steklo-panel-active steklo-panel" : "steklo-panel"}
                                                                    onClick={() => this.setState({ glassModel: e.model })}
                                                                >
                                                                    <div className="steklo-panel-img">
                                                                        <img src={e.img} alt={e.description} />
                                                                    </div>
                                                                    <div className="steklo-panel-text">
                                                                        {e.description}
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </div>
                                                <hr />
                                            </>
                                        }
                                        { tempFrameModel.glass &&
                                            <>
                                                <h3>Steklo na obsvetlobi</h3>                                       
                                                <div className="stekla">
                                                    {tempDoorModel.glassFrameModels.map((e) => {
                                                        return (
                                                            <React.Fragment key={uuidv4()}>
                                                                <div
                                                                    className={this.state.glassFrameModel === e.model ? "steklo-panel-active steklo-panel" : "steklo-panel"}
                                                                    onClick={() => this.setState({ glassFrameModel: e.model })}
                                                                >
                                                                    <div className="steklo-panel-img">
                                                                        <img src={e.img} alt={e.description} />
                                                                    </div>
                                                                    <div className="steklo-panel-text">
                                                                        {e.description}
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </div>
                                            </>
                                        }
                                        
                                    </div> }

                                    { this.state.tabKey === "dodatki" && 
                                        <div>
                                            { !this.state.handleMenuOutOpened && !this.state.handleMenuInOpened &&
                                                <div className="">
                                                    <h3>Model zunanjega ročaja</h3>
                                                    <div
                                                        className="rocaj-panel"
                                                        onClick={() => this.setState({ handleMenuOutOpened: true })}
                                                    >
                                                        <div className="rocaj-panel-img">
                                                           <img src={tempHandleOut.img} alt={tempHandleOut.handleDescription} />
                                                        </div>
                                                        <div className="rocaj-panel-text">
                                                            {tempHandleOut.handleDescription}
                                                        </div>
                                                    </div>

                                                    <hr />

                                                    <h3>Model notranjega ročaja</h3>
                                                    <div
                                                        className="rocaj-panel"
                                                        onClick={() => this.setState({ handleMenuInOpened: true })}
                                                    >
                                                        <div className="rocaj-panel-img">
                                                           <img src={tempHandleIn.img} alt={tempHandleIn.handleDescription} />
                                                        </div>
                                                        <div className="rocaj-panel-text">
                                                            {tempHandleIn.handleDescription}
                                                        </div>
                                                    </div>

                                                    <hr />

                                                    <h3>Čitalec prstnih odtisov</h3>
                                                    <ButtonGroup toggle>
                                                        {citalecPrstnihOdtisov.map((radio, idx) => (
                                                            tempHandleOut.citalecIzvedbe.includes(radio.value) &&
                                                                <ToggleButton
                                                                    key={idx}
                                                                    type="radio"
                                                                    variant="outline-secondary"
                                                                    name="radio"
                                                                    value={radio.value}
                                                                    checked={this.state.citalecPrstnihOdtisov === radio.value}
                                                                    onChange={() => this.setState({ citalecPrstnihOdtisov: radio.value })}
                                                                >
                                                                    {radio.name}
                                                                </ToggleButton>
                                                            ))}
                                                    </ButtonGroup>

                                                    <hr />

                                                    <h3>Ključavnica</h3>
                                                    {doorLock.map((e, i) => {
                                                        return  <Button
                                                                key={i}
                                                                variant="light"
                                                                onClick={() => this.setState({ doorLock: e.lockName })}
                                                                active={e.lockName === this.state.doorLock}
                                                            >
                                                                <img width="100px" alt={`Tip ključavnice: ${e.lockDescription}`} src={e.img} /> <br /> {e.lockDescription}
                                                            </Button> })
                                                    }

                                                    <hr />

                                                    <h3>INOX odbojna letev</h3>
                                                    <ButtonGroup toggle>
                                                        {inoxOdbojnaLetev.map((radio, idx) => (
                                                        <ToggleButton
                                                            key={idx}
                                                            type="radio"
                                                            variant="outline-secondary"
                                                            name="radio"
                                                            value={radio.value}
                                                            checked={this.state.inoxOdbojnaLetev === radio.value}
                                                            onChange={() => this.setState({ inoxOdbojnaLetev: radio.value })}
                                                        >
                                                            {radio.name}
                                                        </ToggleButton>
                                                        ))}
                                                    </ButtonGroup>

                                                    <hr />

                                                    { tempDoorModel.inoxAplikacijeMenu &&
                                                        <React.Fragment>
                                                            <h3>INOX aplikacije</h3>
                                                            <ButtonGroup toggle>
                                                                {inoxAplikacije.map((radio, idx) => (
                                                                <ToggleButton
                                                                    key={idx}
                                                                    type="radio"
                                                                    variant="outline-secondary"
                                                                    name="radio"
                                                                    value={radio.value}
                                                                    checked={this.state.inoxAplikacije === radio.value}
                                                                    onChange={() => this.setState({ inoxAplikacije: radio.value })}
                                                                >
                                                                    {radio.name}
                                                                </ToggleButton>
                                                                ))}
                                                            </ButtonGroup>
                                                            <hr />
                                                        </React.Fragment>
                                                    }

                                                    <h3>Hišna številka</h3>
                                                    <Form>
                                                        <Form.Control
                                                            as="input"
                                                            name="hisnaStevilka"
                                                            onChange={this.handleChange}
                                                            value={this.state.hisnaStevilka}
                                                            maxLength="4"
                                                        >
                                                        </Form.Control>
                                                    </Form>



                                                </div>
                                            }
                                                
                                                { this.state.handleMenuOutOpened &&
                                                    <div className="zunanji-modeli">
                                                        <Button className="button-nazaj" variant="outline-dark" onClick={() => this.setState({ handleMenuOutOpened: false })}  >&larr; Nazaj</Button>
                                                        
                                                        <h2>Zunanji modeli</h2>
                                                        {
                                                            tempDoorModel.handleCategories.includes("kvadratna-inox-30x30") &&
                                                                <>
                                                                    <div className="drzala-panel kvadratna-drzala">
                                                                        <h5>KVADRATNA INOX <br />(30x30) DRŽALA</h5>
                                                                    </div>
                                                                    
                                                                    {handleModels.map((e) => {
                                                                        return (e.format === "outside" || e.format === "both") && (e.category === "kvadratna-inox-30x30") &&
                                                                            <Button
                                                                                key={uuidv4()}
                                                                                variant="light"
                                                                                onClick={() => this.setState({ handleModelOutside: e.handleName, citalecPrstnihOdtisov: "brez" })}
                                                                                active={e.handleName === this.state.handleModelOutside}
                                                                                style={{margin: "1rem", width: "10rem"}}
                                                                            >
                                                                                <img width="100px" alt={`Tip kljuke: ${e.handleDescription}`} src={e.img} /> <br />
                                                                                <p>{e.handleDescription}</p>
                                                                            </Button>})
                                                                    }
                                                                    <hr />
                                                                </>
                                                        
                                                        }
                                                        {
                                                            tempDoorModel.handleCategories.includes("okrogla-inox-fi30") &&
                                                                <>
                                                                    <div className="drzala-panel okrogla-drzala">
                                                                        <h5>OKROGLA INOX <br />(fi 30) DRŽALA</h5>
                                                                    </div>
                                                                    {handleModels.map((e) => {
                                                                        return (e.format === "outside" || e.format === "both") && (e.category === "okrogla-inox-fi30") &&
                                                                            <Button
                                                                                key={uuidv4()}
                                                                                variant="light"
                                                                                onClick={() => this.setState({ handleModelOutside: e.handleName, citalecPrstnihOdtisov: "brez" })}
                                                                                active={e.handleName === this.state.handleModelOutside}
                                                                                style={{margin: "1rem", width: "10rem"}}
                                                                            >
                                                                                <img width="100px" alt={`Tip kljuke: ${e.handleDescription}`} src={e.img} /> <br />
                                                                                <p>{e.handleDescription}</p>
                                                                            </Button>})
                                                                    }
                                                                    <hr />
                                                                </>
                                                        }
                                                        {
                                                            tempDoorModel.handleCategories.includes("utopni") &&
                                                                <>
                                                                    <div className="drzala-panel utopni-rocaji">
                                                                        <h5>UTOPNI ROČAJI</h5>
                                                                    </div>
                                                                    {handleModels.map((e) => {
                                                                        return (e.format === "outside" || e.format === "both") && (e.category === "utopni") && 
                                                                            <Button
                                                                                key={uuidv4()}
                                                                                variant="light"
                                                                                onClick={() => this.setState({ handleModelOutside: e.handleName, citalecPrstnihOdtisov: "brez" })}
                                                                                active={e.handleName === this.state.handleModelOutside}
                                                                                style={{margin: "1rem", width: "10rem"}}
                                                                            >
                                                                                <img width="100px" alt={`Tip kljuke: ${e.handleDescription}`} src={e.img} /> <br />
                                                                                <p>{e.handleDescription}</p>
                                                                            </Button>})
                                                                    }
                                                                    <hr />
                                                                </>
                                                        }
                                                        {
                                                            tempDoorModel.handleCategories.includes("utopni-led") &&
                                                                <>
                                                                    <div className="drzala-panel utopni-rocaji-led">
                                                                        <h5>UTOPNI ROČAJI z led osvetlitvijo</h5>
                                                                    </div>
                                                                    {handleModels.map((e) => {
                                                                        return (e.format === "outside" || e.format === "both") && (e.category === "utopni-led") && 
                                                                            <Button
                                                                                key={uuidv4()}
                                                                                variant="light"
                                                                                onClick={() => this.setState({ handleModelOutside: e.handleName, citalecPrstnihOdtisov: "brez" })}
                                                                                active={e.handleName === this.state.handleModelOutside}
                                                                                style={{margin: "1rem", width: "10rem"}}
                                                                            >
                                                                                <img width="100px" alt={`Tip kljuke: ${e.handleDescription}`} src={e.img} /> <br />
                                                                                <p>{e.handleDescription}</p>
                                                                            </Button>})
                                                                    }
                                                                    <hr />
                                                                </>
                                                        }
                                                        {
                                                            tempDoorModel.handleCategories.includes("vgradna-inox") &&
                                                                <>
                                                                    <div className="drzala-panel inox-vgradni-rocaji">
                                                                        <h5>INOX VGRADNI ROČAJI</h5>
                                                                    </div>
                                                                    {handleModels.map((e) => {
                                                                        return (e.format === "outside" || e.format === "both") && (e.category === "vgradna-inox") && 
                                                                            <Button
                                                                                key={uuidv4()}
                                                                                variant="light"
                                                                                onClick={() => this.setState({ handleModelOutside: e.handleName, citalecPrstnihOdtisov: "brez" })}
                                                                                active={e.handleName === this.state.handleModelOutside}
                                                                                style={{margin: "1rem", width: "10rem"}}
                                                                            >
                                                                                <img width="100px" alt={`Tip kljuke: ${e.handleDescription}`} src={e.img} /> <br />
                                                                                <p>{e.handleDescription}</p>
                                                                            </Button>})
                                                                    }
                                                                    <hr />
                                                                </>
                                                        }
                                                        {
                                                            tempDoorModel.handleCategories.includes("vgradna-inox-led") &&
                                                                <>
                                                                    <div className="drzala-panel inox-vgradni-led-rocaji">
                                                                        <h5>INOX VGRADNI ROČAJI Z LED OSVETLITVIJO</h5>
                                                                    </div>
                                                                    {handleModels.map((e) => {
                                                                        return (e.format === "outside" || e.format === "both") && (e.category === "vgradna-inox-led") && 
                                                                            <Button
                                                                                key={uuidv4()}
                                                                                variant="light"
                                                                                onClick={() => this.setState({ handleModelOutside: e.handleName, citalecPrstnihOdtisov: "brez" })}
                                                                                active={e.handleName === this.state.handleModelOutside}
                                                                                style={{margin: "1rem", width: "10rem"}}
                                                                            >
                                                                                <img width="100px" alt={`Tip kljuke: ${e.handleDescription}`} src={e.img} /> <br />
                                                                                <p>{e.handleDescription}</p>
                                                                            </Button>})
                                                                    }
                                                                    <hr />
                                                                </>
                                                        }
                                                        
                                                        {
                                                            tempDoorModel.handleCategories.includes("ukrivljeni") &&
                                                                <>
                                                                    <div className="drzala-panel ukrivljeni-rocaji">
                                                                        <h5>UKRIVLJENI ROČAJI</h5>
                                                                    </div>
                                                                    {handleModels.map((e) => {
                                                                        return (e.format === "outside" || e.format === "both") && (e.category === "ukrivljeni") && 
                                                                            <Button
                                                                                key={uuidv4()}
                                                                                variant="light"
                                                                                onClick={() => this.setState({ handleModelOutside: e.handleName, citalecPrstnihOdtisov: "brez" })}
                                                                                active={e.handleName === this.state.handleModelOutside}
                                                                                style={{margin: "1rem", width: "10rem"}}
                                                                            >
                                                                                <img width="100px" alt={`Tip kljuke: ${e.handleDescription}`} src={e.img} /> <br />
                                                                                <p>{e.handleDescription}</p>
                                                                            </Button>})
                                                                    }
                                                                    <hr />
                                                                </>
                                                        }

                                                        
                                                        {
                                                            tempDoorModel.handleCategories.includes("kljuke") &&
                                                                <>
                                                                    <div className="drzala-panel kljuke">
                                                                        <h5>KLJUKE</h5>
                                                                    </div>
                                                                    {handleModels.map((e) => {
                                                                        return (e.format === "outside" || e.format === "both") && (e.category === "kljuke") &&
                                                                            <Button
                                                                                key={uuidv4()}
                                                                                variant="light"
                                                                                onClick={() => this.setState({ handleModelOutside: e.handleName, citalecPrstnihOdtisov: "brez" })}
                                                                                active={e.handleName === this.state.handleModelOutside}
                                                                                style={{margin:"1rem", width: "10rem"}}
                                                                            >
                                                                                <img width="100px" alt={`Tip kljuke: ${e.handleDescription}`} src={e.img} /> <br />
                                                                                <p>{e.handleDescription}</p>
                                                                            </Button> })
                                                                    }
                                                                    <hr />
                                                                </>
                                                        }
                                                    </div>
                                                }

                                                { this.state.handleMenuInOpened &&
                                                    <div className="notranji-modeli">
                                                        <Button className="button-nazaj" variant="outline-dark" onClick={() => this.setState({ handleMenuInOpened: false })}  >&larr; Nazaj</Button>
                                                        <h2>Notranji modeli</h2>
                                                        <div className="drzala-panel kljuke">
                                                            <h5>KLJUKE</h5>
                                                        </div>
                                                        {handleModels.map((e, i) => {
                                                            return (e.format === "inside" || e.format === "both") && (e.category === "kljuke") &&
                                                                <Button
                                                                    key={i}
                                                                    variant="light"
                                                                    onClick={() => this.setState({ handleModelInside: e.handleName })}
                                                                    active={e.handleName === this.state.handleModelInside}
                                                                    style={{margin:"1rem", width: "10rem"}}
                                                                >
                                                                    <img width="100px" alt={`Tip kljuke: ${e.handleDescription}`} src={e.img} /> <br />
                                                                    <p>{e.handleDescription}</p>
                                                                </Button> })
                                                        }
                                                        <hr />
                                                    </div>
                                                }
                                        </div>
                                    }
                            </div>  
                        </div>              

                </Row>
            </Container>
            </div>
	  );
  }
}

export default App;