export const houseView = [
    { name: 'Pogled zunaj', value: 'outside' },
    { name: 'Poglej notri', value: 'inside' },
];

export const handleDirection = [
    { name: 'DIN levo', value: 'left' },
    { name: 'DIN desno', value: 'right' },
];

export const inoxOdbojnaLetev = [
    { name: 'Da', value: true },
    { name: 'Ne', value: false },
];

export const inoxAplikacije = [
    { name: 'Da', value: true },
    { name: 'Ne', value: false },
];

export const citalecPrstnihOdtisov = [
    { name: 'Brez', value: "brez" },
    { name: 'Na ročaju', value: "na-rocaju" },
    { name: 'Za ročajem', value: "za-rocajem" },
    { name: 'Poleg ročaja', value: "poleg-rocaja" },
];

export const skritiPanti = [
    { name: 'Da', value: true },
    { name: 'Ne', value: false },
];

export const doorSeries = [
    { name: "Serija deluxe milenium 80", value: "deluxe-milenium-80", description: "Vhodna vrata serije deluxe milenium 80 so izdelana po najvišjih standardih. Primerna so za vgradnjo v stanovanjske in poslovne objekte. Omogočena je maksimalna 4-slojna zasteklitev v skupni debelini 60 mm. Deluxe milenium 80 vhodna vrata dosegajo visoko zvočno in toplotno izolacijo s trojnim tesnenjem.", img: "assets/images/serija/deluxe-milenium-80.jpg", properties: "Toplotna prehodnost: Ud=0.8W/m2K, zvočna prehodnost: Rw=40dB" },
    { name: "Serija premium 120", value: "premium-120", description: "Vhodna vrata serije premium 120 so izdelana po najvišjih standardih. Primerna so za vgradnjo v stanovanjske in poslovne objekte. Zaradi odličnih toplotnih in zvočnih karakteristik so vrata primerna tudi za pasivno gradnjo. Omogočena je maksimalna 4-slojna zasteklitev v skupni debelini 103mm. Premium 120 vhodna vrata dosegajo visoko zvočno in toplotno izolacijo s štiri kratnim tesnenjem.", img: "assets/images/serija/premium-120.jpg", properties: "Toplotna prehodnost: Ud=0.61-0.64W/m2K" },
];

export const handleModels = [
    { handleName: "kljuka-alu", category: "kljuke", length: 0, img: "assets/images/handle-models/kljuka-alu.jpg", handleDescription: "Alu kljuka", format: "both", citalecIzvedbe: ["brez"] },
    { handleName: "kljuka-inox", category: "kljuke", length: 0, img: "assets/images/handle-models/kljuka-inox.jpg", handleDescription: "Inox kljuka", format: "both", citalecIzvedbe: ["brez"] },
    { handleName: "kljuka-atene-q04-crna-matt", category: "kljuke", length: 0, img: "assets/images/handle-models/kljuka-atene-q04-crna-matt.jpg", handleDescription: "Kljuka Atene Q04 matt črna", format: "both", citalecIzvedbe: ["brez"] },
    { handleName: "kljuka-atene-q04-inox", category: "kljuke", length: 0, img: "assets/images/handle-models/kljuka-atene-q04-inox.jpg", handleDescription: "Kljuka inox Atene Q04", format: "both", citalecIzvedbe: ["brez"] },
    { handleName: "kljuka-geneve-q03-crna-matt", category: "kljuke", length: 0, img: "assets/images/handle-models/kljuka-geneve-q03-crna-matt.jpg", handleDescription: "Kljuka Geneve Q03 matt črna", format: "both", citalecIzvedbe: ["brez"] },
    { handleName: "kljuka-geneve-q03-inox", category: "kljuke", length: 0, img: "assets/images/handle-models/kljuka-geneve-q03-inox.jpg", handleDescription: "Kljuka inox Geneve Q03", format: "both", citalecIzvedbe: ["brez"] },
    { handleName: "kljuka-one-10n-minimal", category: "kljuke", length: 0, img: "assets/images/handle-models/kljuka-one-10n-minimal.jpg", handleDescription: "Kljuka One 10N minimal", format: "both", citalecIzvedbe: ["brez"] },
    { handleName: "kljuka-toledo-l14-minimal-inox", category: "kljuke", length: 0, img: "assets/images/handle-models/kljuka-toledo-l14-minimal-inox.jpg", handleDescription: "Kljuka inox Toledo L14 minimal", format: "both", citalecIzvedbe: ["brez"] },
    { handleName: "kljuka-toledo-l14-minimal-crna-matt", category: "kljuke", length: 0, img: "assets/images/handle-models/kljuka-toledo-l14-minimal-crna-matt.jpg", handleDescription: "Kljuka Toledo L14 minimal črna matt", format: "both", citalecIzvedbe: ["brez"] },
    { handleName: "kljuka-rhodos-q51-slim-q01", category: "kljuke", length: 0, img: "assets/images/handle-models/kljuka-rhodos-q51-slim-q01.jpg", handleDescription: "Kljuka Rhodos Q51/slim Q01", format: "both", citalecIzvedbe: ["brez"] },
    { handleName: "rocaj-inox-600", category: "kvadratna-inox-30x30", length: 600, img: "assets/images/handle-models/rocaj-inox-600.jpg", handleDescription: "Ročaj inox (30x30) 600 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-inox-800", category: "kvadratna-inox-30x30", length: 800, img: "assets/images/handle-models/rocaj-inox-800.jpg", handleDescription: "Ročaj inox (30x30) 800 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-inox-1000", category: "kvadratna-inox-30x30", length: 1000, img: "assets/images/handle-models/rocaj-inox-1000.jpg", handleDescription: "Ročaj inox (30x30) 1000 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-inox-1500", category: "kvadratna-inox-30x30", length: 1500, img: "assets/images/handle-models/rocaj-inox-1500.jpg", handleDescription: "Ročaj inox (30x30) 1500 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-inox-1800", category: "kvadratna-inox-30x30", length: 1800, img: "assets/images/handle-models/rocaj-inox-1800.jpg", handleDescription: "Ročaj inox (30x30) 1800 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-inox-okrogli-600", category: "okrogla-inox-fi30", length: 600, img: "assets/images/handle-models/rocaj-okrogli-inox-600.jpg", handleDescription: "Okrogli inox ročaj (fi 30) 600 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-inox-okrogli-800", category: "okrogla-inox-fi30", length: 800, img: "assets/images/handle-models/rocaj-okrogli-inox-800.jpg", handleDescription: "Okrogli inox ročaj (fi 30) 800 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-inox-okrogli-1000", category: "okrogla-inox-fi30", length: 1000, img: "assets/images/handle-models/rocaj-okrogli-inox-1000.jpg", handleDescription: "Okrogli inox ročaj (fi 30) 1000 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-inox-okrogli-1500", category: "okrogla-inox-fi30", length: 1500, img: "assets/images/handle-models/rocaj-okrogli-inox-1500.jpg", handleDescription: "Okrogli inox ročaj (fi 30) 1500 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-inox-okrogli-1800", category: "okrogla-inox-fi30", length: 1800, img: "assets/images/handle-models/rocaj-okrogli-inox-1800.jpg", handleDescription: "Okrogli inox ročaj (fi 30) 1800 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-utopni-1400", category: "utopni", length: 1400, img: "assets/images/handle-models/rocaj-utopni-1400.jpg", handleDescription: "Utopni ročaj 1400 mm", format: "outside", citalecIzvedbe: ["brez", "poleg-rocaja"] },
    { handleName: "rocaj-utopni-1600", category: "utopni", length: 1600, img: "assets/images/handle-models/rocaj-utopni-1600.jpg", handleDescription: "Utopni ročaj 1600 mm", format: "outside", citalecIzvedbe: ["brez", "poleg-rocaja"] },
    { handleName: "rocaj-utopni-1800", category: "utopni", length: 1800, img: "assets/images/handle-models/rocaj-utopni-1800.jpg", handleDescription: "Utopni ročaj 1800 mm", format: "outside", citalecIzvedbe: ["brez", "poleg-rocaja"] },
    { handleName: "rocaj-utopni-2000", category: "utopni", length: 2000, img: "assets/images/handle-models/rocaj-utopni-2000.jpg", handleDescription: "Utopni ročaj 2000 mm", format: "outside", citalecIzvedbe: ["brez", "poleg-rocaja"] },
    { handleName: "rocaj-utopni-led-1400", category: "utopni-led", length: 1400, img: "assets/images/handle-models/rocaj-utopni-led-1400.jpg", handleDescription: "Utopni ročaj z LED osvetlitvijo 1400 mm", format: "outside", citalecIzvedbe: ["brez", "poleg-rocaja"] },
    { handleName: "rocaj-utopni-led-1600", category: "utopni-led", length: 1600, img: "assets/images/handle-models/rocaj-utopni-led-1600.jpg", handleDescription: "Utopni ročaj z LED osvetlitvijo 1600 mm", format: "outside", citalecIzvedbe: ["brez", "poleg-rocaja"] },
    { handleName: "rocaj-utopni-led-1800", category: "utopni-led", length: 1800, img: "assets/images/handle-models/rocaj-utopni-led-1800.jpg", handleDescription: "Utopni ročaj z LED osvetlitvijo 1800 mm", format: "outside", citalecIzvedbe: ["brez", "poleg-rocaja"] },
    { handleName: "rocaj-utopni-led-2000", category: "utopni-led", length: 2000, img: "assets/images/handle-models/rocaj-utopni-led-2000.jpg", handleDescription: "Utopni ročaj z LED osvetlitvijo 2000 mm", format: "outside", citalecIzvedbe: ["brez", "poleg-rocaja"] },
    { handleName: "rocaj-inox-vgradni-1400", category: "vgradna-inox", length: 1400, img: "assets/images/handle-models/rocaj-inox-vgradni-1400.jpg", handleDescription: "Inox vgradni ročaj 1400 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-inox-vgradni-1600", category: "vgradna-inox", length: 1600, img: "assets/images/handle-models/rocaj-inox-vgradni-1600.jpg", handleDescription: "Inox vgradni ročaj 1600 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-inox-vgradni-1800", category: "vgradna-inox", length: 1800, img: "assets/images/handle-models/rocaj-inox-vgradni-1800.jpg", handleDescription: "Inox vgradni ročaj 1800 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-inox-vgradni-2000", category: "vgradna-inox", length: 2000, img: "assets/images/handle-models/rocaj-inox-vgradni-2000.jpg", handleDescription: "Inox vgradni ročaj 2000 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-inox-vgradni-led-1400", category: "vgradna-inox-led", length: 1400, img: "assets/images/handle-models/rocaj-inox-vgradni-led-1400.jpg", handleDescription: "Inox vgradni ročaj z LED osvetlitvijo 1400 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-inox-vgradni-led-1600", category: "vgradna-inox-led", length: 1600, img: "assets/images/handle-models/rocaj-inox-vgradni-led-1600.jpg", handleDescription: "Inox vgradni ročaj z LED osvetlitvijo 1600 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-inox-vgradni-led-1800", category: "vgradna-inox-led", length: 1800, img: "assets/images/handle-models/rocaj-inox-vgradni-led-1800.jpg", handleDescription: "Inox vgradni ročaj z LED osvetlitvijo 1800 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-inox-vgradni-led-2000", category: "vgradna-inox-led", length: 2000, img: "assets/images/handle-models/rocaj-inox-vgradni-led-2000.jpg", handleDescription: "Inox vgradni ročaj z LED osvetlitvijo 2000 mm", format: "outside", citalecIzvedbe: ["brez", "na-rocaju", "za-rocajem"] },
    { handleName: "rocaj-ukrivljeni-inox-1000", category: "ukrivljeni", length: 1000, img: "assets/images/handle-models/rocaj-ukrivljeni-inox-1000.jpg", handleDescription: "Inox ukrivljeni ročaj", format: "outside", citalecIzvedbe: ["brez", "poleg-rocaja"] },
];

export const doorLock = [
    { lockName: "avtomatska-5-tockovna-kljucavnica", lockDescription: "Avtomatska 5-točkovna ključavnica", img: "assets/images/door-lock/avtomatska-5-tockovna-kljucavnica.jpg" },
];

export const doorDecor = [
    { decorName: "Hrast", value: "hrast", img: "assets/images/decor/hrast.jpg" },
    { decorName: "Macesen", value: "macesen", img: "assets/images/decor/macesen.jpg" },
    { decorName: "Mahagonij", value: "mehagonij", img: "assets/images/decor/mehagonij.jpg" },
    { decorName: "Temni hrast", value: "temni-hrast", img: "assets/images/decor/temni-hrast.jpg" },
    { decorName: "Zlati hrast", value: "zlati-hrast", img: "assets/images/decor/zlati-hrast.jpg" },
];

export const doorDimensions = {
    defaultDoorMinWidth: 650,
    defaultDoorMaxWidth: 1300,
    defaultDoorMinHeight: 1800,
    defaultDoorMaxHeight: 2800,
    minObWidth: 125,
    maxObWidth: 1500,
    minObHeight: 125,
    maxObHeight: 1000
};

export const frameModels = [
    {
        frameName: "Brez obsvetlobe",
        value: "01",
        img: "assets/images/frame-models/01.jpg",
        defaultHandleDirection: "right",
        glass: false,
        frameWidth: 1100,
        frameHeight: 2150,
        doorWidth: 1000,
        doorHeight: 2100,
        glassRight: 0,
        glassLeft: 0,
        glassTop: 0,
    },
    {
        frameName: "Obsvetloba desno",
        value: "02",
        img: "assets/images/frame-models/02.jpg",
        defaultHandleDirection: "right",
        glass: true,
        frameWidth: 1450,
        frameHeight: 2150,
        doorWidth: 1000,
        doorHeight: 2100,
        glassRight: 300,
        glassLeft: 0,
        glassTop: 0,
    },
    {
        frameName: "Obsvetloba levo",
        value: "03",
        img: "assets/images/frame-models/03.jpg",
        defaultHandleDirection: "left",
        glass: true,
        frameWidth: 1450,
        frameHeight: 2150,
        doorWidth: 1000,
        doorHeight: 2100,
        glassRight: 0,
        glassLeft: 300,
        glassTop: 0,
    },
    {
        frameName: "Obsvetloba levo in desno",
        value: "04",
        img: "assets/images/frame-models/04.jpg",
        defaultHandleDirection: "right",
        glass: true,
        frameWidth: 1800,
        frameHeight: 2150,
        doorWidth: 1000,
        doorHeight: 2100,
        glassRight: 300,
        glassLeft: 300,
        glassTop: 0,
    },
    {
        frameName: "Obsvetloba desno z nadsvetlobo",
        value: "05",
        img: "assets/images/frame-models/05.jpg",
        defaultHandleDirection: "right",
        glass: true,
        frameWidth: 1450,
        frameHeight: 2500,
        doorWidth: 1000,
        doorHeight: 2100,
        glassRight: 300,
        glassLeft: 0,
        glassTop: 300,
    },
    {
        frameName: "Obsvetloba levo z nadsvetlobo",
        value: "06",
        img: "assets/images/frame-models/06.jpg",
        defaultHandleDirection: "left",
        glass: true,
        frameWidth: 1450,
        frameHeight: 2500,
        doorWidth: 1000,
        doorHeight: 2100,
        glassRight: 0,
        glassLeft: 300,
        glassTop: 300,
    },
    {
        frameName: "Obsvetloba levo in desno z nadsvetlobo",
        value: "07",
        img: "assets/images/frame-models/07.jpg",
        defaultHandleDirection: "right",
        glass: true,
        frameWidth: 1800,
        frameHeight: 2500,
        doorWidth: 1000,
        doorHeight: 2100,
        glassRight: 300,
        glassLeft: 300,
        glassTop: 300,
    },
]

export const doorModels = [
    {
        doorName: "CER 001",
        value: "CER-001",
        img: "assets/images/door-models/CER-001.jpg",
        defaultHandle: "rocaj-inox-800",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: false,
        glassDefaultModel: "",
        glassModels: [],
        glassFrame: false,
        glassFrameDefaultModel: "f_01",
        glassFrameModels: [
            { model: "f_01", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-01.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: false,
        inoxAplikacije: false,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 002",
        value: "CER-002",
        img: "assets/images/door-models/CER-002.jpg",
        defaultHandle: "rocaj-inox-800",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: true,
        glassDefaultModel: "02-1",
        glassModels: [
            { model: "02-1", description: "Steklo z izrezom", img: "assets/images/glass/02-1.jpg"},
            { model: "02-2", description: "Satinirano steklo", img: "assets/images/glass/02-2.jpg"},
            { model: "02-3", description: "Prosojno steklo", img: "assets/images/glass/02-3.jpg"},
        ],
        glassFrameDefaultModel: "f_02",
        glassFrameModels: [
            { model: "f_02", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-02.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: true,
        inoxAplikacije: false,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 003",
        value: "CER-003",
        img: "assets/images/door-models/CER-003.jpg",
        defaultHandle: "rocaj-inox-800",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: true, 
        glassDefaultModel: "03-1",
        glassModels: [
            { model: "03-1", description: "Satinirano steklo", img: "assets/images/glass/03-1.jpg"},
            { model: "03-2", description: "Prosojno steklo", img: "assets/images/glass/03-2.jpg"},
            { model: "03-3", description: "Satinirano steklo z izrezom", img: "assets/images/glass/03-3.jpg"},
        ],
        glassFrameDefaultModel: "f_03",
        glassFrameModels: [
            { model: "f_03", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-03.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: true,
        inoxAplikacije: true,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 004",
        value: "CER-004",
        img: "assets/images/door-models/CER-004.jpg",
        defaultHandle: "rocaj-inox-800",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: true,
        glassDefaultModel: "04-1",
        glassModels: [
            { model: "04-1", description: "Satinirano steklo", img: "assets/images/glass/04-1.jpg"},
            { model: "04-2", description: "Prosojno steklo", img: "assets/images/glass/04-2.jpg"},
            { model: "04-3", description: "Satinirano steklo z izrezom", img: "assets/images/glass/04-3.jpg"},
        ],
        glassFrameDefaultModel: "f_satinirano",
        glassFrameModels: [
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: true,
        inoxAplikacije: false,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 005",
        value: "CER-005",
        img: "assets/images/door-models/CER-005.jpg",
        defaultHandle: "rocaj-inox-1500",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: true,
        glassDefaultModel: "05-1",
        glassModels: [
            { model: "05-1", description: "Satinirano steklo z izrezi", img: "assets/images/glass/05-1.jpg"},
            { model: "05-2", description: "Satinirano steklo", img: "assets/images/glass/05-2.jpg"},
            { model: "05-3", description: "Prosojno steklo", img: "assets/images/glass/05-3.jpg"},
        ],
        glassFrameDefaultModel: "f_05",
        glassFrameModels: [
            { model: "f_05", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-05.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: false,
        inoxAplikacije: false,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 006",
        value: "CER-006",
        img: "assets/images/door-models/CER-006.jpg",
        defaultHandle: "rocaj-inox-1500",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: false,
        glassDefaultModel: "",
        glassModels: [],
        glassFrameDefaultModel: "f_06",
        glassFrameModels: [
            { model: "f_06", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-06.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: false,
        inoxAplikacije: true,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 007",
        value: "CER-007",
        img: "assets/images/door-models/CER-007.jpg",
        defaultHandle: "rocaj-inox-1500",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: false,
        glassDefaultModel: "",
        glassModels: [],
        glassFrameDefaultModel: "f_07",
        glassFrameModels: [
            { model: "f_07", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-07.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: false,
        inoxAplikacije: false,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 008",
        value: "CER-008",
        img: "assets/images/door-models/CER-008.jpg",
        defaultHandle: "rocaj-inox-1500",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: false,
        glassDefaultModel: "",
        glassModels: [],
        glassFrameDefaultModel: "f_satinirano",
        glassFrameModels: [
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: false,
        inoxAplikacije: false,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 009",
        value: "CER-009",
        img: "assets/images/door-models/CER-009.jpg",
        defaultHandle: "rocaj-inox-1800",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: false,
        glassDefaultModel: "",
        glassModels: [],
        glassFrameDefaultModel: "f_satinirano",
        glassFrameModels: [
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: false,
        inoxAplikacije: false,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 010",
        value: "CER-010",
        img: "assets/images/door-models/CER-010.jpg",
        defaultHandle: "rocaj-inox-1500",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: true,
        glassDefaultModel: "10-1",
        glassModels: [
            { model: "10-1", description: "Satinirano steklo z izrezi", img: "assets/images/glass/10-1.jpg"},
            { model: "10-2", description: "Satinirano steklo zgoraj prosojno", img: "assets/images/glass/10-2.jpg"},
            { model: "10-3", description: "Satinirano steklo", img: "assets/images/glass/10-3.jpg"},
            { model: "10-4", description: "Prosojno steklo", img: "assets/images/glass/10-4.jpg"},
        ],
        glassFrameDefaultModel: "f_10",
        glassFrameModels: [
            { model: "f_10", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-10.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: false,
        inoxAplikacije: false,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 011",
        value: "CER-011",
        img: "assets/images/door-models/CER-011.jpg",
        defaultHandle: "rocaj-utopni-1400",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: true,
        glassDefaultModel: "11-1",
        glassModels: [
            { model: "11-1", description: "Satinirano steklo z izrezi", img: "assets/images/glass/11-1.jpg"},
            { model: "11-2", description: "Satinirano steklo zgoraj prosojno", img: "assets/images/glass/11-2.jpg"},
            { model: "11-3", description: "Satinirano steklo", img: "assets/images/glass/11-3.jpg"},
            { model: "11-4", description: "Prosojno steklo", img: "assets/images/glass/11-4.jpg"},
        ],
        glassFrameDefaultModel: "f_11",
        glassFrameModels: [
            { model: "f_11", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-11.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: false,
        inoxAplikacije: false,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 012",
        value: "CER-012",
        img: "assets/images/door-models/CER-012.jpg",
        defaultHandle: "rocaj-ukrivljeni-inox-1000",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: true,
        glassDefaultModel: "12-1",
        glassModels: [
            { model: "12-1", description: "Satinirano steklo z izrezi 1", img: "assets/images/glass/12-1.jpg"},
            { model: "12-2", description: "Satinirano steklo z izrezi 2", img: "assets/images/glass/12-2.jpg"},
            { model: "12-3", description: "Satinirano steklo", img: "assets/images/glass/12-3.jpg"},
            { model: "12-4", description: "Prosojno steklo", img: "assets/images/glass/12-4.jpg"},
        ],
        glassFrameDefaultModel: "f_12",
        glassFrameModels: [
            { model: "f_12", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-12.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: true,
        inoxAplikacije: false,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 013",
        value: "CER-013",
        img: "assets/images/door-models/CER-013.jpg",
        defaultHandle: "rocaj-utopni-1400",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: false,
        glassDefaultModel: "",
        glassModels: [],
        glassFrameDefaultModel: "f_satinirano",
        glassFrameModels: [
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: true,
        inoxAplikacije: true,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 014",
        value: "CER-014",
        img: "assets/images/door-models/CER-014.jpg",
        defaultHandle: "rocaj-inox-800",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: false,
        glassDefaultModel: "",
        glassModels: [],
        glassFrameDefaultModel: "f_14",
        glassFrameModels: [
            { model: "f_14", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-14.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: true,
        inoxAplikacije: true,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 015",
        value: "CER-015",
        img: "assets/images/door-models/CER-015.jpg",
        defaultHandle: "rocaj-inox-1500",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: true,
        glassDefaultModel: "15-1",
        glassModels: [
            { model: "15-1", description: "Satinirano in prosojno steklo z izrezi", img: "assets/images/glass/15-1.jpg"},
            { model: "15-2", description: "Satinirano steklo z izrezi", img: "assets/images/glass/15-2.jpg"},
            { model: "15-3", description: "Satinirano steklo", img: "assets/images/glass/15-3.jpg"},
            { model: "15-4", description: "Prosojno steklo", img: "assets/images/glass/15-4.jpg"},
        ],
        glassFrameDefaultModel: "f_15",
        glassFrameModels: [
            { model: "f_15", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-15.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: true,
        inoxAplikacije: true,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 016",
        value: "CER-016",
        img: "assets/images/door-models/CER-016.jpg",
        defaultHandle: "rocaj-inox-800",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: true,
        glassDefaultModel: "16-1",
        glassModels: [
            { model: "16-1", description: "Satinirano steklo z izrezi", img: "assets/images/glass/16-1.jpg"},
            { model: "16-2", description: "Satinirano in prosojno steklo z izrezi", img: "assets/images/glass/16-2.jpg"},
            { model: "16-3", description: "Satinirano steklo", img: "assets/images/glass/16-3.jpg"},
            { model: "16-4", description: "Prosojno steklo", img: "assets/images/glass/16-4.jpg"},
        ],
        glassFrameDefaultModel: "f_16",
        glassFrameModels: [
            { model: "f_16", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-16.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: true,
        inoxAplikacije: false,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 017",
        value: "CER-017",
        img: "assets/images/door-models/CER-017.jpg",
        defaultHandle: "rocaj-inox-vgradni-1800",
        defaultHandleX: 125,
        handleCategories: ["vgradna-inox", "vgradna-inox-led", "kljuke"],
        primaryColorOptions: "color-decor",
        glass: false,
        glassDefaultModel: "",
        glassModels: [],
        glassFrameDefaultModel: "f_17",
        glassFrameModels: [
            { model: "f_17", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-17.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: true,
        inoxAplikacije: false,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 018",
        value: "CER-018",
        img: "assets/images/door-models/CER-018.jpg",
        defaultHandle: "rocaj-inox-1000",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: true,
        glassDefaultModel: "18-1",
        glassModels: [
            { model: "18-1", description: "Satinirano steklo z izrezi", img: "assets/images/glass/18-1.jpg"},
            { model: "18-2", description: "Satinirano steklo", img: "assets/images/glass/18-2.jpg"},
            { model: "18-3", description: "Prosojno steklo", img: "assets/images/glass/18-3.jpg"},
        ],
        glassFrameDefaultModel: "f_18",
        glassFrameModels: [
            { model: "f_18", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-18.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: true,
        inoxAplikacije: false,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 019",
        value: "CER-019",
        img: "assets/images/door-models/CER-019.jpg",
        defaultHandle: "rocaj-inox-1000",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: true,
        glassDefaultModel: "19-1",
        glassModels: [
            { model: "19-1", description: "Satinirano steklo z izrezi", img: "assets/images/glass/19-1.jpg"},
            { model: "19-2", description: "Satinirano steklo", img: "assets/images/glass/19-2.jpg"},
            { model: "19-3", description: "Prosojno steklo", img: "assets/images/glass/19-3.jpg"},
        ],
        glassFrameDefaultModel: "f_19",
        glassFrameModels: [
            { model: "f_19", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-19.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: true,
        inoxAplikacije: false,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 020",
        value: "CER-020",
        img: "assets/images/door-models/CER-020.jpg",
        defaultHandle: "rocaj-inox-1000",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color",
        glass: true,
        glassDefaultModel: "20-1",
        glassModels: [
            { model: "20-1", description: "Satinirano steklo z izrezi", img: "assets/images/glass/20-1.jpg"},
            { model: "20-2", description: "Satinirano steklo", img: "assets/images/glass/20-2.jpg"},
            { model: "20-3", description: "Prosojno steklo", img: "assets/images/glass/20-3.jpg"},
        ],
        glassFrameDefaultModel: "f_20",
        glassFrameModels: [
            { model: "f_20", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-20.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: false,
        inoxAplikacije: false,
        secondDecorSet: true,
        secondDecorDefault: "zlati-hrast",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 021",
        value: "CER-021",
        img: "assets/images/door-models/CER-021.jpg",
        defaultHandle: "rocaj-inox-1000",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: true,
        glassDefaultModel: "21-1",
        glassModels: [
            { model: "21-1", description: "Satinirano steklo z izrezi", img: "assets/images/glass/21-1.jpg"},
            { model: "21-2", description: "Satinirano steklo", img: "assets/images/glass/21-2.jpg"},
            { model: "21-3", description: "Prosojno steklo", img: "assets/images/glass/21-3.jpg"},
        ],
        glassFrameDefaultModel: "f_satinirano",
        glassFrameModels: [
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: false,
        inoxAplikacije: false,
        secondDecorSet: false,
        secondDecorDefault: "temni-hrast",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 022",
        value: "CER-022",
        img: "assets/images/door-models/CER-022.jpg",
        defaultHandle: "rocaj-inox-1000",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: true,
        glassDefaultModel: "22-1",
        glassModels: [
            { model: "22-1", description: "Satinirano steklo z izrezi", img: "assets/images/glass/22-1.jpg"},
            { model: "22-2", description: "Satinirano steklo", img: "assets/images/glass/22-2.jpg"},
            { model: "22-3", description: "Prosojno steklo", img: "assets/images/glass/22-3.jpg"},
        ],
        glassFrameDefaultModel: "f_22",
        glassFrameModels: [
            { model: "f_22", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-22.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: true,
        inoxAplikacije: true,
        secondDecorSet: false,
        secondDecorDefault: "temni-hrast",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 023",
        value: "CER-023",
        img: "assets/images/door-models/CER-023.jpg",
        defaultHandle: "rocaj-inox-1500",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color",
        glass: false,
        glassDefaultModel: "",
        glassModels: [],
        glassFrameDefaultModel: "f_07",
        glassFrameModels: [
            { model: "f_07", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-07.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: false,
        inoxAplikacije: false,
        secondDecorSet: true,
        secondDecorDefault: "zlati-hrast",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },{
        doorName: "CER 024",
        value: "CER-024",
        img: "assets/images/door-models/CER-024.jpg",
        defaultHandle: "rocaj-inox-1500",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "utopni", "utopni-led", "vgradna-inox", "vgradna-inox-led", "ukrivljeni"],
        primaryColorOptions: "color",
        glass: false,
        glassDefaultModel: "",
        glassModels: [],
        glassFrameDefaultModel: "f_07",
        glassFrameModels: [
            { model: "f_07", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-07.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: false,
        inoxAplikacije: false,
        secondDecorSet: true,
        secondDecorDefault: "zlati-hrast",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 025",
        value: "CER-025",
        img: "assets/images/door-models/CER-025.jpg",
        defaultHandle: "rocaj-inox-1500",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: false,
        glassDefaultModel: "",
        glassModels: [],
        glassFrameDefaultModel: "f_14",
        glassFrameModels: [
            { model: "f_14", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-14.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: true,
        inoxAplikacije: true,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 026",
        value: "CER-026",
        img: "assets/images/door-models/CER-026.jpg",
        defaultHandle: "rocaj-inox-1500",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: false,
        glassDefaultModel: "",
        glassModels: [],
        glassFrameDefaultModel: "f_14",
        glassFrameModels: [
            { model: "f_14", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-14.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: true,
        inoxAplikacije: true,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
    {
        doorName: "CER 027",
        value: "CER-027",
        img: "assets/images/door-models/CER-027.jpg",
        defaultHandle: "rocaj-inox-1500",
        defaultHandleX: 125,
        handleCategories: ["kljuke", "kvadratna-inox-30x30", "okrogla-inox-fi30", "ukrivljeni"],
        primaryColorOptions: "color-decor",
        glass: false,
        glassDefaultModel: "",
        glassModels: [],
        glassFrameDefaultModel: "f_14",
        glassFrameModels: [
            { model: "f_14", description: "Steklo z izrezi", img: "assets/images/glass-frame/f-14.jpg"},
            { model: "f_satinirano", description: "Satinirano steklo", img: "assets/images/glass-frame/f-satinirano.jpg"},
            { model: "f_prosojno", description: "Prosojno steklo", img: "assets/images/glass-frame/f-prosojno.jpg"},
        ],
        secondColor: false,
        tolkalo: false,
        inoxAplikacijeMenu: true,
        inoxAplikacije: true,
        secondDecorSet: false,
        secondDecorDefault: "",
        primaryColorDefault: "RAL-7016",
        primaryDecorSet: false,
        defaultColor1: "",
        defaultColor2: "",
        defaultDecor1: "",
        defaultDecor2: "",
        maxX: 0,
        maxY: 0,
    },
];

export const colorButtons = [
    { colorName: "RAL 7016 FS", style: "#383e42", value: "RAL-7016", className: "RAL-button", colorDescription:"Anthracite grey", category: "Standardne strukturne finozrnate barve" },
    { colorName: "RAL 8014 FS", style: "#4a3526", value: "RAL-8014-FS", className: "RAL-button", colorDescription:"Sepia brown", category: "Standardne strukturne finozrnate barve" },
    { colorName: "RAL 9016 FS", style: "#f1f0ea", value: "RAL-9016-FS", className: "RAL-button-inv", colorDescription:"Traffic white", category: "Standardne strukturne finozrnate barve" },
    { colorName: "TR 021", style: "#383e42", value: "TR-021", className: "RAL-button", colorDescription:"", category: "Extra metalic" },
    { colorName: "RAL 9006 FS", style: "#a1a1a0", value: "RAL-9006-FS", className: "RAL-button", colorDescription: "White aluminium", category: "Extra metalic" },
    { colorName: "RAL 9007 FS", style: "#878581", value: "RAL-9007-FS", className: "RAL-button", colorDescription: "Grey aluminium", category: "Extra metalic" },
    { colorName: "RAL 1000", style: "#cdba88", value: "RAL-1000", className: "RAL-button", colorDescription:"Green beige", category: "Rumeni odtenki" },
    { colorName: "RAL 1000", style: "#cdba88", value: "RAL-1000", className: "RAL-button", colorDescription:"Green beige", category: "Rumeni odtenki" },
    { colorName: "RAL 1001", style: "#d0b084", value: "RAL-1001", className: "RAL-button", colorDescription:"Beige", category: "Rumeni odtenki" },
    { colorName: "RAL 1002", style: "#d2aa6d", value: "RAL-1002", className: "RAL-button", colorDescription:"Sand yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1003", style: "#f9a800", value: "RAL-1003", className: "RAL-button", colorDescription:"Signal yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1004", style: "#e49e00", value: "RAL-1004", className: "RAL-button", colorDescription:"Golden yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1005", style: "#cb8e00", value: "RAL-1005", className: "RAL-button", colorDescription:"Honey yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1006", style: "#e29000", value: "RAL-1006", className: "RAL-button", colorDescription:"Maize yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1007", style: "#e88c00", value: "RAL-1007", className: "RAL-button", colorDescription:"Daffodil yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1011", style: "#af804f", value: "RAL-1011", className: "RAL-button", colorDescription:"Brown beige", category: "Rumeni odtenki" },
    { colorName: "RAL 1012", style: "#ddaf27", value: "RAL-1012", className: "RAL-button", colorDescription:"Lemon yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1013", style: "#e3d9c6", value: "RAL-1013", className: "RAL-button-inv", colorDescription:"Oyster white", category: "Rumeni odtenki" },
    { colorName: "RAL 1014", style: "#ddc49a", value: "RAL-1014", className: "RAL-button", colorDescription:"Ivory", category: "Rumeni odtenki" },
    { colorName: "RAL 1015", style: "#e6d2b5", value: "RAL-1015", className: "RAL-button", colorDescription:"Light ivory", category: "Rumeni odtenki" },
    { colorName: "RAL 1016", style: "#f1dd38", value: "RAL-1016", className: "RAL-button", colorDescription:"Sulfur yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1017", style: "#f6a950", value: "RAL-1017", className: "RAL-button", colorDescription:"Saffron yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1018", style: "#faca30", value: "RAL-1018", className: "RAL-button", colorDescription:"Zinc yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1019", style: "#a48f7a", value: "RAL-1019", className: "RAL-button", colorDescription:"Grey beige", category: "Rumeni odtenki" },
    { colorName: "RAL 1020", style: "#a08f65", value: "RAL-1020", className: "RAL-button", colorDescription:"Olive yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1021", style: "#f6b600", value: "RAL-1021", className: "RAL-button", colorDescription:"Colza yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1023", style: "#f7b500", value: "RAL-1023", className: "RAL-button", colorDescription:"Traffic yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1024", style: "#ba8f4c", value: "RAL-1024", className: "RAL-button", colorDescription:"Ochre yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1026", style: "#ffff00", value: "RAL-1026", className: "RAL-button-inv", colorDescription:"Luminous yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1027", style: "#a77f0e", value: "RAL-1027", className: "RAL-button", colorDescription:"Curry", category: "Rumeni odtenki" },
    { colorName: "RAL 1028", style: "#ff9b00", value: "RAL-1028", className: "RAL-button", colorDescription:"Melon yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1032", style: "#e2a300", value: "RAL-1032", className: "RAL-button", colorDescription:"Broom yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1033", style: "#f99a1c", value: "RAL-1033", className: "RAL-button", colorDescription:"Dahlia yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1034", style: "#eb9c52", value: "RAL-1034", className: "RAL-button", colorDescription:"Pastel yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 1035", style: "#908370", value: "RAL-1035", className: "RAL-button", colorDescription:"Pearl beige", category: "Rumeni odtenki" },
    { colorName: "RAL 1036", style: "#80643f", value: "RAL-1036", className: "RAL-button", colorDescription:"Pearl gold", category: "Rumeni odtenki" },
    { colorName: "RAL 1037", style: "#f09200", value: "RAL-1037", className: "RAL-button", colorDescription:"Sun yellow", category: "Rumeni odtenki" },
    { colorName: "RAL 2000", style: "#da6e00", value: "RAL-2000", className: "RAL-button", colorDescription: "Yellow orange", category: "Oranžni odtenki" },
    { colorName: "RAL 2001", style: "#ba481b", value: "RAL-2001", className: "RAL-button", colorDescription: "Red Orange", category: "Oranžni odtenki" },
    { colorName: "RAL 2002", style: "#bf3922", value: "RAL-2002", className: "RAL-button", colorDescription: "Vermilion", category: "Oranžni odtenki" },
    { colorName: "RAL 2003", style: "#f67828", value: "RAL-2003", className: "RAL-button", colorDescription: "Pastel orange", category: "Oranžni odtenki" },
    { colorName: "RAL 2004", style: "#e25303", value: "RAL-2004", className: "RAL-button", colorDescription: "Pure orange", category: "Oranžni odtenki" },
    { colorName: "RAL 2005", style: "#ff4d06", value: "RAL-2005", className: "RAL-button", colorDescription: "Luminous orange", category: "Oranžni odtenki" },
    { colorName: "RAL 2007", style: "#ffb200", value: "RAL-2007", className: "RAL-button", colorDescription: "Luminous bright orange", category: "Oranžni odtenki" },
    { colorName: "RAL 2008", style: "#ed6b21", value: "RAL-2008", className: "RAL-button", colorDescription: "Bright red orange", category: "Oranžni odtenki" },
    { colorName: "RAL 2009", style: "#de5307", value: "RAL-2009", className: "RAL-button", colorDescription: "Traffic orange", category: "Oranžni odtenki" },
    { colorName: "RAL 2010", style: "#d05d28", value: "RAL-2010", className: "RAL-button", colorDescription: "Signal orange", category: "Oranžni odtenki" },
    { colorName: "RAL 2011", style: "#e26e0e", value: "RAL-2011", className: "RAL-button", colorDescription: "Deep orange", category: "Oranžni odtenki" },
    { colorName: "RAL 2012", style: "#d5654d", value: "RAL-2012", className: "RAL-button", colorDescription: "Salmon orange", category: "Oranžni odtenki" },
    { colorName: "RAL 2013", style: "#923e25", value: "RAL-2013", className: "RAL-button", colorDescription: "Pearl orange", category: "Oranžni odtenki" },
    { colorName: "RAL 3000", style: "#a72920", value: "RAL-3000", className: "RAL-button", colorDescription: "Flame red", category: "Rdeči odtenki" },
    { colorName: "RAL 3001", style: "#9b2423", value: "RAL-3001", className: "RAL-button", colorDescription: "Signal red", category: "Rdeči odtenki" },
    { colorName: "RAL 3002", style: "#9b2321", value: "RAL-3002", className: "RAL-button", colorDescription: "Carmine red", category: "Rdeči odtenki" },
    { colorName: "RAL 3003", style: "#861a22", value: "RAL-3003", className: "RAL-button", colorDescription: "Ruby red", category: "Rdeči odtenki" },
    { colorName: "RAL 3004", style: "#6b1c23", value: "RAL-3004", className: "RAL-button", colorDescription: "Purple red", category: "Rdeči odtenki" },
    { colorName: "RAL 3005", style: "#59191f", value: "RAL-3005", className: "RAL-button", colorDescription: "Wine red", category: "Rdeči odtenki" },
    { colorName: "RAL 3007", style: "#3e2022", value: "RAL-3007", className: "RAL-button", colorDescription: "Black red", category: "Rdeči odtenki" },
    { colorName: "RAL 3009", style: "#6d342d", value: "RAL-3009", className: "RAL-button", colorDescription: "Oxide red", category: "Rdeči odtenki" },
    { colorName: "RAL 3011", style: "#792423", value: "RAL-3011", className: "RAL-button", colorDescription: "Brown red", category: "Rdeči odtenki" },
    { colorName: "RAL 3012", style: "#c6846d", value: "RAL-3012", className: "RAL-button", colorDescription: "Beige red", category: "Rdeči odtenki" },
    { colorName: "RAL 3013", style: "#972e25", value: "RAL-3013", className: "RAL-button", colorDescription: "Tomato red", category: "Rdeči odtenki" },
    { colorName: "RAL 3014", style: "#cb7375", value: "RAL-3014", className: "RAL-button", colorDescription: "Antique pink", category: "Rdeči odtenki" },
    { colorName: "RAL 3015", style: "#d8a0a6", value: "RAL-3015", className: "RAL-button", colorDescription: "Light pink", category: "Rdeči odtenki" },
    { colorName: "RAL 3016", style: "#a63d2f", value: "RAL-3016", className: "RAL-button", colorDescription: "Coral red", category: "Rdeči odtenki" },
    { colorName: "RAL 3017", style: "#cb555d", value: "RAL-3017", className: "RAL-button", colorDescription: "Rose", category: "Rdeči odtenki" },
    { colorName: "RAL 3018", style: "#c73f4a", value: "RAL-3018", className: "RAL-button", colorDescription: "Strawberry red", category: "Rdeči odtenki" },
    { colorName: "RAL 3020", style: "#bb1e10", value: "RAL-3020", className: "RAL-button", colorDescription: "Traffic red", category: "Rdeči odtenki" },
    { colorName: "RAL 3022", style: "#cf6955", value: "RAL-3022", className: "RAL-button", colorDescription: "Salom pink", category: "Rdeči odtenki" },
    { colorName: "RAL 3024", style: "#ff2d21", value: "RAL-3024", className: "RAL-button", colorDescription: "Luminous red", category: "Rdeči odtenki" },
    { colorName: "RAL 3026", style: "#ff2a1b", value: "RAL-3026", className: "RAL-button", colorDescription: "Luminous bright red", category: "Rdeči odtenki" },
    { colorName: "RAL 3027", style: "#ab273c", value: "RAL-3027", className: "RAL-button", colorDescription: "Raspberry red", category: "Rdeči odtenki" },
    { colorName: "RAL 3028", style: "#cc2c24", value: "RAL-3028", className: "RAL-button", colorDescription: "Pure red", category: "Rdeči odtenki" },
    { colorName: "RAL 3031", style: "#a63437", value: "RAL-3031", className: "RAL-button", colorDescription: "Orient red", category: "Rdeči odtenki" },
    { colorName: "RAL 3032", style: "#701d23", value: "RAL-3032", className: "RAL-button", colorDescription: "Pearl ruby red", category: "Rdeči odtenki" },
    { colorName: "RAL 3033", style: "#a53a2d", value: "RAL-3033", className: "RAL-button", colorDescription: "Pearl pink", category: "Rdeči odtenki" },
    { colorName: "RAL 4001", style: "#816183", value: "RAL-4001", className: "RAL-button", colorDescription: "Red lilac", category: "Vijolični odtenki" },
    { colorName: "RAL 4002", style: "#8d3c4b", value: "RAL-4002", className: "RAL-button", colorDescription: "Red violet", category: "Vijolični odtenki" },
    { colorName: "RAL 4003", style: "#c4618c", value: "RAL-4003", className: "RAL-button", colorDescription: "Heather violet", category: "Vijolični odtenki" },
    { colorName: "RAL 4004", style: "#651e38", value: "RAL-4004", className: "RAL-button", colorDescription: "Claret violet", category: "Vijolični odtenki" },
    { colorName: "RAL 4005", style: "#76689a", value: "RAL-4005", className: "RAL-button", colorDescription: "Blue lilac", category: "Vijolični odtenki" },
    { colorName: "RAL 4006", style: "#903373", value: "RAL-4006", className: "RAL-button", colorDescription: "Traffic purple", category: "Vijolični odtenki" },
    { colorName: "RAL 4007", style: "#47243c", value: "RAL-4007", className: "RAL-button", colorDescription: "Purple violet", category: "Vijolični odtenki" },
    { colorName: "RAL 4008", style: "#844c82", value: "RAL-4008", className: "RAL-button", colorDescription: "Signal violet", category: "Vijolični odtenki" },
    { colorName: "RAL 4009", style: "#9d8692", value: "RAL-4009", className: "RAL-button", colorDescription: "Pastel violet", category: "Vijolični odtenki" },
    { colorName: "RAL 4010", style: "#bc4077", value: "RAL-4010", className: "RAL-button", colorDescription: "Telemagenta", category: "Vijolični odtenki" },
    { colorName: "RAL 4011", style: "#6e6387", value: "RAL-4011", className: "RAL-button", colorDescription: "Pearl violet", category: "Vijolični odtenki" },
    { colorName: "RAL 4012", style: "#6b6b7f", value: "RAL-4012", className: "RAL-button", colorDescription: "Pearl blackberry", category: "Vijolični odtenki" },
    { colorName: "RAL 5000", style: "#314f6f", value: "RAL-5000", className: "RAL-button", colorDescription: "Violet blue", category: "Modri odtenki" },
    { colorName: "RAL 5001", style: "#0f4c64", value: "RAL-5001", className: "RAL-button", colorDescription: "Green blue", category: "Modri odtenki" },
    { colorName: "RAL 5002", style: "#00387b", value: "RAL-5002", className: "RAL-button", colorDescription: "Ultramarine blue", category: "Modri odtenki" },
    { colorName: "RAL 5003", style: "#1f3855", value: "RAL-5003", className: "RAL-button", colorDescription: "Sapphire blue", category: "Modri odtenki" },
    { colorName: "RAL 5004", style: "#191e28", value: "RAL-5004", className: "RAL-button", colorDescription: "Black blue", category: "Modri odtenki" },
    { colorName: "RAL 5005", style: "#005387", value: "RAL-5005", className: "RAL-button", colorDescription: "Signal blue", category: "Modri odtenki" },
    { colorName: "RAL 5007", style: "#376b8c", value: "RAL-5007", className: "RAL-button", colorDescription: "Brilliant blue", category: "Modri odtenki" },
    { colorName: "RAL 5008", style: "#2b3a44", value: "RAL-5008", className: "RAL-button", colorDescription: "Grey blue", category: "Modri odtenki" },
    { colorName: "RAL 5009", style: "#225f78", value: "RAL-5009", className: "RAL-button", colorDescription: "Azure blue", category: "Modri odtenki" },
    { colorName: "RAL 5010", style: "#004f7c", value: "RAL-5010", className: "RAL-button", colorDescription: "Gentian blue", category: "Modri odtenki" },
    { colorName: "RAL 5011", style: "#1a2b3c", value: "RAL-5011", className: "RAL-button", colorDescription: "Steel blue", category: "Modri odtenki" },
    { colorName: "RAL 5012", style: "#0089b6", value: "RAL-5012", className: "RAL-button", colorDescription: "Light blue", category: "Modri odtenki" },
    { colorName: "RAL 5013", style: "#193153", value: "RAL-5013", className: "RAL-button", colorDescription: "Cobalt blue", category: "Modri odtenki" },
    { colorName: "RAL 5014", style: "#637d96", value: "RAL-5014", className: "RAL-button", colorDescription: "Pigeon blue", category: "Modri odtenki" },
    { colorName: "RAL 5015", style: "#007cb0", value: "RAL-5015", className: "RAL-button", colorDescription: "Sky blue", category: "Modri odtenki" },
    { colorName: "RAL 5017", style: "#005b8c", value: "RAL-5017", className: "RAL-button", colorDescription: "Traffic blue", category: "Modri odtenki" },
    { colorName: "RAL 5018", style: "#058b8c", value: "RAL-5018", className: "RAL-button", colorDescription: "Turquoise blue", category: "Modri odtenki" },
    { colorName: "RAL 5019", style: "#005e83", value: "RAL-5019", className: "RAL-button", colorDescription: "Capri blue", category: "Modri odtenki" },
    { colorName: "RAL 5020", style: "#00414b", value: "RAL-5020", className: "RAL-button", colorDescription: "Ocean blue", category: "Modri odtenki" },
    { colorName: "RAL 5021", style: "#007577", value: "RAL-5021", className: "RAL-button", colorDescription: "Water blue", category: "Modri odtenki" },
    { colorName: "RAL 5022", style: "#222d5a", value: "RAL-5022", className: "RAL-button", colorDescription: "Night blue", category: "Modri odtenki" },
    { colorName: "RAL 5023", style: "#42698c", value: "RAL-5023", className: "RAL-button", colorDescription: "Distant blue", category: "Modri odtenki" },
    { colorName: "RAL 5024", style: "#6093ac", value: "RAL-5024", className: "RAL-button", colorDescription: "Pastel blue", category: "Modri odtenki" },
    { colorName: "RAL 5025", style: "#21697c", value: "RAL-5025", className: "RAL-button", colorDescription: "Pearl gentian blue", category: "Modri odtenki" },
    { colorName: "RAL 5026", style: "#0f3052", value: "RAL-5026", className: "RAL-button", colorDescription: "Pearl night blue", category: "Modri odtenki" },
    { colorName: "RAL 6000", style: "#3c7460", value: "RAL-6000", className: "RAL-button", colorDescription: "Patina green", category: "Zeleni odtenki" },
    { colorName: "RAL 6001", style: "#366735", value: "RAL-6001", className: "RAL-button", colorDescription: "Emerald green", category: "Zeleni odtenki" },
    { colorName: "RAL 6002", style: "#325928", value: "RAL-6002", className: "RAL-button", colorDescription: "Leaf green", category: "Zeleni odtenki" },
    { colorName: "RAL 6003", style: "#50533c", value: "RAL-6003", className: "RAL-button", colorDescription: "Olive green", category: "Zeleni odtenki" },
    { colorName: "RAL 6004", style: "#024442", value: "RAL-6004", className: "RAL-button", colorDescription: "Blue green", category: "Zeleni odtenki" },
    { colorName: "RAL 6005", style: "#114232", value: "RAL-6005", className: "RAL-button", colorDescription: "Moss green", category: "Zeleni odtenki" },
    { colorName: "RAL 6006", style: "#3c392e", value: "RAL-6006", className: "RAL-button", colorDescription: "Grey olive", category: "Zeleni odtenki" },
    { colorName: "RAL 6007", style: "#2c3222", value: "RAL-6007", className: "RAL-button", colorDescription: "Bottle green", category: "Zeleni odtenki" },
    { colorName: "RAL 6008", style: "#37342a", value: "RAL-6008", className: "RAL-button", colorDescription: "Brown green", category: "Zeleni odtenki" },
    { colorName: "RAL 6009", style: "#27352a", value: "RAL-6009", className: "RAL-button", colorDescription: "Fir green", category: "Zeleni odtenki" },
    { colorName: "RAL 6010", style: "#4d6f39", value: "RAL-6010", className: "RAL-button", colorDescription: "Grass green", category: "Zeleni odtenki" },
    { colorName: "RAL 6011", style: "#6c7c59", value: "RAL-6011", className: "RAL-button", colorDescription: "Reseda green", category: "Zeleni odtenki" },
    { colorName: "RAL 6012", style: "#303d3a", value: "RAL-6012", className: "RAL-button", colorDescription: "Black green", category: "Zeleni odtenki" },
    { colorName: "RAL 6013", style: "#7d765a", value: "RAL-6013", className: "RAL-button", colorDescription: "Reed green", category: "Zeleni odtenki" },
    { colorName: "RAL 6014", style: "#474135", value: "RAL-6014", className: "RAL-button", colorDescription: "Yellow olive", category: "Zeleni odtenki" },
    { colorName: "RAL 6015", style: "#3d3d36", value: "RAL-6015", className: "RAL-button", colorDescription: "Black olive", category: "Zeleni odtenki" },
    { colorName: "RAL 6016", style: "#00694c", value: "RAL-6016", className: "RAL-button", colorDescription: "Turquoise green", category: "Zeleni odtenki" },
    { colorName: "RAL 6017", style: "#587f40", value: "RAL-6017", className: "RAL-button", colorDescription: "May green", category: "Zeleni odtenki" },
    { colorName: "RAL 6018", style: "#61993b", value: "RAL-6018", className: "RAL-button", colorDescription: "Yellow green", category: "Zeleni odtenki" },
    { colorName: "RAL 6019", style: "#b9ceac", value: "RAL-6019", className: "RAL-button", colorDescription: "Pastel green", category: "Zeleni odtenki" },
    { colorName: "RAL 6020", style: "#37422f", value: "RAL-6020", className: "RAL-button", colorDescription: "Chrome green", category: "Zeleni odtenki" },
    { colorName: "RAL 6021", style: "#8a9977", value: "RAL-6021", className: "RAL-button", colorDescription: "Pale green", category: "Zeleni odtenki" },
    { colorName: "RAL 6022", style: "#3a3327", value: "RAL-6022", className: "RAL-button", colorDescription: "Olive drab", category: "Zeleni odtenki" },
    { colorName: "RAL 6024", style: "#008351", value: "RAL-6024", className: "RAL-button", colorDescription: "Traffic green", category: "Zeleni odtenki" },
    { colorName: "RAL 6025", style: "#5e6e3b", value: "RAL-6025", className: "RAL-button", colorDescription: "Fern green", category: "Zeleni odtenki" },
    { colorName: "RAL 6026", style: "#005f4e", value: "RAL-6026", className: "RAL-button", colorDescription: "Opal green", category: "Zeleni odtenki" },
    { colorName: "RAL 6027", style: "#7ebab5", value: "RAL-6027", className: "RAL-button", colorDescription: "Light green", category: "Zeleni odtenki" },
    { colorName: "RAL 6028", style: "#315442", value: "RAL-6028", className: "RAL-button", colorDescription: "Pine green", category: "Zeleni odtenki" },
    { colorName: "RAL 6029", style: "#006f3d", value: "RAL-6029", className: "RAL-button", colorDescription: "Mint green", category: "Zeleni odtenki" },
    { colorName: "RAL 6032", style: "#237f52", value: "RAL-6032", className: "RAL-button", colorDescription: "Signal green", category: "Zeleni odtenki" },
    { colorName: "RAL 6033", style: "#46877f", value: "RAL-6033", className: "RAL-button", colorDescription: "Mint turquoise", category: "Zeleni odtenki" },
    { colorName: "RAL 6034", style: "#7aacac", value: "RAL-6034", className: "RAL-button", colorDescription: "Pastel turquoise", category: "Zeleni odtenki" },
    { colorName: "RAL 6035", style: "#194d25", value: "RAL-6035", className: "RAL-button", colorDescription: "Pearl green", category: "Zeleni odtenki" },
    { colorName: "RAL 6036", style: "#04574b", value: "RAL-6036", className: "RAL-button", colorDescription: "Pearl opal green", category: "Zeleni odtenki" },
    { colorName: "RAL 6037", style: "#008b29", value: "RAL-6037", className: "RAL-button", colorDescription: "Pure green", category: "Zeleni odtenki" },
    { colorName: "RAL 6038", style: "#00b51a", value: "RAL-6038", className: "RAL-button", colorDescription: "Luminous green", category: "Zeleni odtenki" },
    { colorName: "RAL 7000", style: "#7a888e", value: "RAL-7000", className: "RAL-button", colorDescription: "Squirrel grey", category: "Sivi odtenki" },
    { colorName: "RAL 7001", style: "#8c969d", value: "RAL-7001", className: "RAL-button", colorDescription: "Silver grey", category: "Sivi odtenki" },
    { colorName: "RAL 7002", style: "#817863", value: "RAL-7002", className: "RAL-button", colorDescription: "Olive grey", category: "Sivi odtenki" },
    { colorName: "RAL 7003", style: "#7a7669", value: "RAL-7003", className: "RAL-button", colorDescription: "Moss grey", category: "Sivi odtenki" },
    { colorName: "RAL 7004", style: "#9b9b9b", value: "RAL-7004", className: "RAL-button", colorDescription: "Signal grey", category: "Sivi odtenki" },
    { colorName: "RAL 7005", style: "#6c6e6b", value: "RAL-7005", className: "RAL-button", colorDescription: "Mouse grey", category: "Sivi odtenki" },
    { colorName: "RAL 7006", style: "#766a5e", value: "RAL-7006", className: "RAL-button", colorDescription: "Beige grey", category: "Sivi odtenki" },
    { colorName: "RAL 7008", style: "#745e3d", value: "RAL-7008", className: "RAL-button", colorDescription: "Khaki grey", category: "Sivi odtenki" },
    { colorName: "RAL 7009", style: "#5d6058", value: "RAL-7009", className: "RAL-button", colorDescription: "Green grey", category: "Sivi odtenki" },
    { colorName: "RAL 7010", style: "#585c56", value: "RAL-7010", className: "RAL-button", colorDescription: "Tarpaulin grey", category: "Sivi odtenki" },
    { colorName: "RAL 7011", style: "#52595d", value: "RAL-7011", className: "RAL-button", colorDescription: "Iron grey", category: "Sivi odtenki" },
    { colorName: "RAL 7012", style: "#575d5e", value: "RAL-7012", className: "RAL-button", colorDescription: "Basalt grey", category: "Sivi odtenki" },
    { colorName: "RAL 7013", style: "#575044", value: "RAL-7013", className: "RAL-button", colorDescription: "Brown grey", category: "Sivi odtenki" },
    { colorName: "RAL 7015", style: "#4f5358", value: "RAL-7015", className: "RAL-button", colorDescription: "Slate grey", category: "Sivi odtenki" },
    { colorName: "RAL 7016", style: "#383e42", value: "RAL-7016", className: "RAL-button", colorDescription: "Anthracite grey", category: "Sivi odtenki" },
    { colorName: "RAL 7021", style: "#2f3234", value: "RAL-7021", className: "RAL-button", colorDescription: "Black grey", category: "Sivi odtenki" },
    { colorName: "RAL 7022", style: "#4c4a44", value: "RAL-7022", className: "RAL-button", colorDescription: "Umbra grey", category: "Sivi odtenki" },
    { colorName: "RAL 7023", style: "#808076", value: "RAL-7023", className: "RAL-button", colorDescription: "Concrete grey", category: "Sivi odtenki" },
    { colorName: "RAL 7024", style: "#45494e", value: "RAL-7024", className: "RAL-button", colorDescription: "Graphite grey", category: "Sivi odtenki" },
    { colorName: "RAL 7026", style: "#374345", value: "RAL-7026", className: "RAL-button", colorDescription: "Granite grey", category: "Sivi odtenki" },
    { colorName: "RAL 7030", style: "#928e85", value: "RAL-7030", className: "RAL-button", colorDescription: "Stone grey", category: "Sivi odtenki" },
    { colorName: "RAL 7031", style: "#5b686d", value: "RAL-7031", className: "RAL-button", colorDescription: "Blue grey", category: "Sivi odtenki" },
    { colorName: "RAL 7032", style: "#b5b0a1", value: "RAL-7032", className: "RAL-button", colorDescription: "Pebble grey", category: "Sivi odtenki" },
    { colorName: "RAL 7033", style: "#7f8274", value: "RAL-7033", className: "RAL-button", colorDescription: "Cement grey", category: "Sivi odtenki" },
    { colorName: "RAL 7034", style: "#92886f", value: "RAL-7034", className: "RAL-button", colorDescription: "Yellow grey", category: "Sivi odtenki" },
    { colorName: "RAL 7035", style: "#c5c7c4", value: "RAL-7035", className: "RAL-button-inv", colorDescription: "Light grey", category: "Sivi odtenki" },
    { colorName: "RAL 7036", style: "#979392", value: "RAL-7036", className: "RAL-button", colorDescription: "Platinum grey", category: "Sivi odtenki" },
    { colorName: "RAL 7037", style: "#7a7b7a", value: "RAL-7037", className: "RAL-button", colorDescription: "Dusty grey", category: "Sivi odtenki" },
    { colorName: "RAL 7038", style: "#b0b0a9", value: "RAL-7038", className: "RAL-button", colorDescription: "Agate grey", category: "Sivi odtenki" },
    { colorName: "RAL 7039", style: "#6b665e", value: "RAL-7039", className: "RAL-button", colorDescription: "Quartz grey", category: "Sivi odtenki" },
    { colorName: "RAL 7040", style: "#989ea1", value: "RAL-7040", className: "RAL-button", colorDescription: "Window grey", category: "Sivi odtenki" },
    { colorName: "RAL 7042", style: "#8e9291", value: "RAL-7042", className: "RAL-button", colorDescription: "Traffic grey A", category: "Sivi odtenki" },
    { colorName: "RAL 7043", style: "#4f5250", value: "RAL-7043", className: "RAL-button", colorDescription: "Traffic grey B", category: "Sivi odtenki" },
    { colorName: "RAL 7044", style: "#b7b3a8", value: "RAL-7044", className: "RAL-button", colorDescription: "Silk grey", category: "Sivi odtenki" },
    { colorName: "RAL 7045", style: "#8d9295", value: "RAL-7045", className: "RAL-button", colorDescription: "Telegrey 1", category: "Sivi odtenki" },
    { colorName: "RAL 7046", style: "#7f868a", value: "RAL-7046", className: "RAL-button", colorDescription: "Telegrey 2", category: "Sivi odtenki" },
    { colorName: "RAL 7047", style: "#c8c8c7", value: "RAL-7047", className: "RAL-button-inv", colorDescription: "Telegrey 4", category: "Sivi odtenki" },
    { colorName: "RAL 7048", style: "#817b73", value: "RAL-7048", className: "RAL-button", colorDescription: "Pearl mouse grey", category: "Sivi odtenki" },
    { colorName: "RAL 8000", style: "#89693e", value: "RAL-8000", className: "RAL-button", colorDescription: "Green brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8001", style: "#9d622b", value: "RAL-8001", className: "RAL-button", colorDescription: "Ochre brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8002", style: "#794d3e", value: "RAL-8002", className: "RAL-button", colorDescription: "Signal brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8003", style: "#7e4b26", value: "RAL-8003", className: "RAL-button", colorDescription: "Clay brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8004", style: "#8d4931", value: "RAL-8004", className: "RAL-button", colorDescription: "Copper brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8007", style: "#70452a", value: "RAL-8007", className: "RAL-button", colorDescription: "Fawn brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8008", style: "#724a25", value: "RAL-8008", className: "RAL-button", colorDescription: "Olive brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8011", style: "#5a3826", value: "RAL-8011", className: "RAL-button", colorDescription: "Nut brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8012", style: "#66332b", value: "RAL-8012", className: "RAL-button", colorDescription: "Red brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8014", style: "#4a3526", value: "RAL-8014", className: "RAL-button", colorDescription: "Sepia brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8015", style: "#5e2f26", value: "RAL-8015", className: "RAL-button", colorDescription: "Chestnut brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8016", style: "#4c2b20", value: "RAL-8016", className: "RAL-button", colorDescription: "Mahogany brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8017", style: "#442f29", value: "RAL-8017", className: "RAL-button", colorDescription: "Chocolate brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8019", style: "#3d3635", value: "RAL-8019", className: "RAL-button", colorDescription: "Grey brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8022", style: "#1a1718", value: "RAL-8022", className: "RAL-button", colorDescription: "Black brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8023", style: "#a45729", value: "RAL-8023", className: "RAL-button", colorDescription: "Orange brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8024", style: "#795038", value: "RAL-8024", className: "RAL-button", colorDescription: "Beige brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8025", style: "#755847", value: "RAL-8025", className: "RAL-button", colorDescription: "Pale brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8028", style: "#513a2a", value: "RAL-8028", className: "RAL-button", colorDescription: "Terra brown", category: "Rjavi odtenki" },
    { colorName: "RAL 8029", style: "#7f4031", value: "RAL-8029", className: "RAL-button", colorDescription: "Pearl copper", category: "Rjavi odtenki" },
    { colorName: "RAL 9001", style: "#e9e0d2", value: "RAL-9001", className: "RAL-button-inv", colorDescription: "Cream", category: "Beli in črni odtenki" },
    { colorName: "RAL 9002", style: "#d7d5cb", value: "RAL-9002", className: "RAL-button-inv", colorDescription: "Grey white", category: "Beli in črni odtenki" },
    { colorName: "RAL 9003", style: "#ecece7", value: "RAL-9003", className: "RAL-button-inv", colorDescription: "Signal white", category: "Beli in črni odtenki" },
    { colorName: "RAL 9004", style: "#2b2b2c", value: "RAL-9004", className: "RAL-button", colorDescription: "Signal black", category: "Beli in črni odtenki" },
    { colorName: "RAL 9005", style: "#0e0e10", value: "RAL-9005", className: "RAL-button", colorDescription: "Jet black", category: "Beli in črni odtenki" },
    { colorName: "RAL 9006", style: "#a1a1a0", value: "RAL-9006", className: "RAL-button", colorDescription: "White aluminium", category: "Beli in črni odtenki" },
    { colorName: "RAL 9007", style: "#878581", value: "RAL-9007", className: "RAL-button", colorDescription: "Grey aluminium", category: "Beli in črni odtenki" },
    { colorName: "RAL 9010", style: "#f1ece1", value: "RAL-9010", className: "RAL-button-inv", colorDescription: "Pure white", category: "Beli in črni odtenki" },
    { colorName: "RAL 9011", style: "#27292b", value: "RAL-9011", className: "RAL-button", colorDescription: "Graphite black", category: "Beli in črni odtenki" },
    { colorName: "RAL 9016", style: "#f1f0ea", value: "RAL-9016", className: "RAL-button-inv", colorDescription: "Traffic white", category: "Beli in črni odtenki" },
    { colorName: "RAL 9017", style: "#2a292a", value: "RAL-9017", className: "RAL-button", colorDescription: "Traffic black", category: "Beli in črni odtenki" },
    { colorName: "RAL 9018", style: "#c8cbc4", value: "RAL-9018", className: "RAL-button-inv", colorDescription: "Papyrus white", category: "Beli in črni odtenki" },
    { colorName: "RAL 9022", style: "#858583", value: "RAL-9022", className: "RAL-button", colorDescription: "Pearl light grey", category: "Beli in črni odtenki" },
    { colorName: "RAL 9023", style: "#797b7a", value: "RAL-9023", className: "RAL-button", colorDescription: "Pearl dark grey ", category: "Beli in črni odtenki" },
];