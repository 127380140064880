import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';

class CER023 extends Component {

    render() {

        const izrezi = [];
        {console.log(this.props.colorDecor2)}
        for (var i = 1; i <= 10; i++ ) {
            izrezi.push(
                <React.Fragment key={uuidv4()}>
                    <rect
                        x={(this.props.doorWidth-10)-i*60}
                        y={5}
                        width={45}
                        height={this.props.doorHeight-5}
                        style={{fill: "rgba(246, 246, 246, 0.0)", stroke: "rgba(0, 0, 0, 0.55)", strokeWidth: "7"}}
                    />
                </React.Fragment>
                    /*<React.Fragment key={uuidv4()}>
                        <rect
                            key={uuidv4()}
                            x={10}
                            y={i*(this.props.doorHeight/5)}
                            width={this.props.doorWidth-20}
                            height={7}
                            style={{fill: "rgba(0, 0, 0, 0.35)"}}
                        />
                        <rect
                            key={uuidv4()}
                            x={10}
                            y={i*(this.props.doorHeight/5)+18}
                            width={this.props.doorWidth-20}
                            height={7}
                            style={{fill: "rgba(246, 246, 246, 0.35)"}}
                        />
                    </React.Fragment>*/
            )
        };

        return (
            <React.Fragment>
                { this.props.view === "outside" &&
                    <>
                        <g>
                            <rect
                                //fill={`url(#temni-hrast)`}
                                fill={`url(#${this.props.colorDecor2})`}
                                x={(this.props.doorWidth-610)}
                                y={5}
                                width={600}
                                height={this.props.doorHeight-5}
                            />
                            {izrezi}
                        </g>
                        {this.props.view === "outside" &&
                        this.props.handleDirection === "right" ?
                            <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="start" x={20} y={200} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                            :
                            <text textAnchor="end" x={this.props.doorWidth-20} y={200} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                        }
                    </>
                }
            </React.Fragment>
        );
    }
}

export default CER023;